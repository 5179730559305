import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { logger } from '@core/helpers/logger';
import { vimeoEmbedUrl, youtubeEmbedUrl } from '@core/helpers/video';
import {
  AccountDetailsType,
  AccountService,
  BillingService,
  ConfigService,
  FilesService,
  SchoolsService,
  UserServicesService,
} from '@core/services';
import { CurrenciesService } from '@core/services/currencies.service';
import { BillingApiCurrencyRateResponseDto } from '@lingo2-billing-sdk/models';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getBillingSettings } from '@store/reducers/data.reducer';
import { getMe } from '@store/reducers/profile.reducer';
import {
  ESchoolParticipantRole,
  EUserServiceType,
  IBillingSettings,
  ITeachingSubject,
  Language,
  MediaFormatEnum,
  otherSubjectId,
  otherSubjectLevelId,
  ProfileMediaModeEnum,
  Subject as BaseSubject,
  SubjectCategoryEnum,
  SubjectLevel,
  SubjectLevelsRegistry,
  SubjectsRegistry,
  User,
  UserService,
  CurrencyEnum,
} from 'lingo2-models';
import { IFindAccountsFilter } from 'lingo2-models/dist/account/interfaces/find-accounts-filter';
import { uniqBy } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiBullet, OnUiButtonTabOption, OnUiFormStepState, OnUiRadioOption, OnUiSelectOption } from 'onclass-ui';
import { combineLatest, filter, Observable, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  bullet,
  discountOptions,
  groupsOptions,
  meetingsLimitCourseOptions,
  meetingsLimitMiniCourseOptions,
  meetingsLimitOptions,
  participantsGroupLimit,
  participantsSingleLimit,
  timeDurationOptions,
  typeText,
  userServicesTypes,
  weekdaysOptions,
} from './user-service-form-3.variables';

type stepType = 'type' | 'teachers' | 'props' | 'main';

@Component({
  selector: 'app-user-service-form-3',
  templateUrl: './user-service-form-3.component.html',
  styleUrls: ['./user-service-form-3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserServiceForm3Component extends ChangableComponent implements OnInit {
  @Input() set userService(value: UserService) {
    this._userService = value;
    this.createForm();
    if (this.isSchool) {
      this.teachersOptions = this.teacherOptionsFilter();
    }
  }

  @Input() set teachingSubjects(teachingSubjects: ITeachingSubject[]) {
    this._teachingSubjects = teachingSubjects;
  }

  @Output() changed = new EventEmitter<UserService>();
  public form: UntypedFormGroup;
  public visible = false;
  public types: OnUiRadioOption[] = userServicesTypes;
  public groups: Array<OnUiButtonTabOption & { dataTest: string }> = groupsOptions;
  public weekdays: OnUiButtonTabOption[] = weekdaysOptions;
  public bullet: OnUiBullet = bullet;
  public typeText: string[] = typeText;
  public teachingSubjectsOptions: OnUiSelectOption[] = [];
  public categoryOptions: OnUiSelectOption[] = [];
  public participantsLimitOptions: OnUiSelectOption[] = participantsSingleLimit;
  public beginAgeOptions: OnUiSelectOption[] = [];
  public endAgeOptions: OnUiSelectOption[] = [];
  public subjectLevelOptions: OnUiSelectOption[] = [];
  public languagesOptions: OnUiSelectOption[] = [];
  public priceOptions: OnUiSelectOption[] = [];
  public discountOptions: OnUiSelectOption[] = discountOptions;
  public timeDurationOptions: OnUiSelectOption[] = this.formatAsOption(timeDurationOptions);
  public meetingsLimitOptions: OnUiSelectOption[] = meetingsLimitOptions;
  public teachersOptions: Array<Partial<User>> = [];
  public safeMediaUrl: SafeResourceUrl;
  public isMediaPrepared: boolean;
  public showMore: boolean;
  public currencies: BillingApiCurrencyRateResponseDto[];

  private activeVideoTab: Partial<User>;
  private displayInvalidForm: boolean;
  private _userService: UserService;
  private _teachingSubjects: ITeachingSubject[] = [];
  private _languages: Language[] = [];
  private _me: User;
  private _billingSettings: Partial<IBillingSettings>;
  private _subjectLevelsRegistry: SubjectLevelsRegistry;
  private _subjectRegistry: SubjectsRegistry;

  constructor(
    private accountService: AccountService,
    private billingService: BillingService,
    private currencyService: CurrenciesService,
    private filesService: FilesService,
    private store: Store,
    private router: Router,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private userServicesService: UserServicesService,
    private translateService: TranslateService,
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.createForm();

    combineLatest([
      this.configService.userServiceCategories$.pipe(filter((categories) => !!categories)),
      this.configService.languages$.pipe(filter((languages) => !!languages)),
      this.store.select(getMe),
      this.store.select(getBillingSettings).pipe(filter((settings) => !!settings)),
      this.configService.subjectLevels2$.pipe(filter((subjectLevelsRegistry) => !!subjectLevelsRegistry)),
      this.configService.subjectsV2$.pipe(filter((subjectRegistry) => !!subjectRegistry)),
    ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([categories, languages, me, billingSettings, subjectLevelsRegistry, subjectRegistry]) => {
          this.categoryOptions = categories.map((item) => ({ value: item.id, title: item.title }));
          this._languages = languages;
          this._me = me;
          this._billingSettings = billingSettings;
          this._subjectLevelsRegistry = subjectLevelsRegistry;
          this._subjectRegistry = subjectRegistry;

          if (this.isSchool) {
            this.addTeachersControls();
            this.loadProfiles();
          }

          this.prepareLanguagesOptions();
          this.setLanguage();
          this.prepareTeachingSubjectsOptions();
          this.preparePriceOptions();
          this.loadModel();
          this.generateBeginAgeOptions();
          this.generateEndAgeOptions();
          if (this.isNew) {
            this.onChangedType(EUserServiceType.single);
          }

          this.visible = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.visible = true;
        },
      });

    this.currencyService
      .getCurrenciesRate()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (currencies) => {
          this.currencies = currencies;
          this.preparePriceOptions();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public formatAsOption(options: number[]): OnUiSelectOption[] {
    return options.map(
      (item) =>
        ({
          value: item,
          title: item + ' ' + this.translateService.instant('user-service-form-3.time-duration.minutes'),
        } as OnUiSelectOption),
    );
  }

  public onChangedGroup(form: boolean, idx: number) {
    const controls = this.getFormArray('group').controls;
    const [single, group] = controls;

    controls.forEach((_control, index) => {
      if (index === idx) {
        return;
      }
      _control.patchValue(false);
    });

    if (single.value && !group.value) {
      this.participantsLimitOptions = participantsSingleLimit;
      this.form.patchValue({ participants_limit: 1 });
      this.getControl('participants_limit').disable();
      this.types[1].disabled = false;
    } else {
      this.participantsLimitOptions = participantsGroupLimit;
      this.form.patchValue({ participants_limit: null });
      this.getControl('participants_limit').enable();
      this.types[1].disabled = true;
      if (this.getControl('type').value === EUserServiceType.regular) {
        this.form.patchValue({ type: null });
      }
    }

    logger.log('onChangedGroup', form, controls, this.form);
    this.detectChanges();
  }

  public onChangedType(type: EUserServiceType) {
    const controls = this.getFormArray('group').controls;
    const [single] = controls;
    this.participantsLimitOptions = single.value ? participantsSingleLimit : participantsGroupLimit;

    switch (type) {
      case EUserServiceType.single:
        this.form.patchValue({ participants_limit: single.value ? 1 : null, meetings_limit: 1 });
        break;
      case EUserServiceType.regular:
        this.form.patchValue({ participants_limit: single.value ? 1 : null, meetings_limit: 1 });
        break;
      case EUserServiceType.mini:
        this.meetingsLimitOptions = meetingsLimitMiniCourseOptions;
        this.form.patchValue({ participants_limit: single.value ? 1 : null, meetings_limit: null });
        break;
      case EUserServiceType.course:
        this.meetingsLimitOptions = meetingsLimitCourseOptions;
        this.form.patchValue({ participants_limit: single.value ? 1 : null, meetings_limit: null });
        break;
    }
    this.form.patchValue({ type });
    logger.log('onChangedType', type, this.form);
    this.detectChanges();
  }

  public selectSubjectId(subject_id: number) {
    this.form.patchValue({ subject_id });
    this.setSubjectLevelsBySubject(subject_id);
    this.teachersOptions = this.teacherOptionsFilter();
  }

  public onChangeDescription(e) {
    // logger.log('onChangeDescription', e);
    this.detectChanges();
  }

  public onChangedCategoryId(e) {}

  public onChangedParticipantsLimit(e) {}

  public onChangeAge(e) {
    const age_from = this.getControl('age_from').value;
    const age_to = this.getControl('age_to').value;
    if (age_from != null && age_to != null && age_from > age_to) {
      this.form.patchValue({
        age_from: age_to,
        age_to: age_from,
      });
    }
    // logger.log('onChangedParticipantsLimit', e, age_from, age_to);
    this.generateEndAgeOptions();
    this.detectChanges();
  }

  public onFocusedChangeSkills(focus: boolean, idx: number) {
    const value = this.getFormArray('skills').value;
    const isFilled = !value.filter((_v) => !_v).length;
    const isLastItem = value.length - 1 === idx;
    if (!focus && isFilled) {
      this.addSkill();
    }

    if (!focus && !value[idx]?.length && !isLastItem) {
      this.removeSkill(idx);
    }
    logger.log('onFocusedChangeSkills', value, isFilled);
  }

  public onFocusedChangeRequirement(focus: boolean, idx: number) {
    const value = this.getFormArray('requirements').value;
    const isFilled = !value.filter((_v) => !_v).length;
    const isLastItem = value.length - 1 === idx;
    if (!focus && isFilled) {
      this.addRequirement();
    }

    if (!focus && !value[idx]?.length && !isLastItem) {
      this.removeRequirement(idx);
    }
    logger.log('onFocusedChangeRequirement', value, isFilled);
  }

  public onChangedMedia(args: string, idx: number) {
    logger.log('changedMedia', args, idx);
    const len = this.getFormArray('media').length;

    if (len - 1 !== idx && args) {
      return;
    }

    if (args) {
      this.addMedia();
    } else {
      this.removeMedia(idx);
    }
  }

  public onChangedWeekdays(args: boolean) {
    logger.log('onChangedDaysOfWeek', this.form.value);
  }

  public onChangeLanguage(language_id: number) {
    this.form.patchValue({ language_id });
  }

  public addSkill() {
    this.getFormArray('skills').push(UserServiceForm3Component.skillFormControl());
  }

  public removeSkill(index: number) {
    this.getFormArray('skills').removeAt(index);
    this.form.markAsDirty();
  }

  public onChangeRequirement(e) {
    logger.log('onChangeRequirement', e);
    // this.addRequirement();
  }

  public addRequirement() {
    this.getFormArray('requirements').push(UserServiceForm3Component.requirementFormControl());
  }

  public removeRequirement(index: number) {
    this.getFormArray('requirements').removeAt(index);
    this.form.markAsDirty();
  }

  public addMedia() {
    this.getFormArray('media').push(UserServiceForm3Component.mediaFormControl());
  }

  public removeMedia(index: number) {
    this.getFormArray('media').removeAt(index);
    this.form.markAsDirty();
  }

  public onChangeTeacher(e) {
    logger.log('onChangeTeacher--------------------', e, this.form.value);
    if (this.teachersTabs().length === 1) {
      this.onActiveVideo(this.teachersTabs()[0]);
    }
  }

  public addTeacher() {
    this.getFormArray('teachers').push(UserServiceForm3Component.teacherFormControl());
    this.teachersOptions = this.teacherOptionsFilter();
  }

  public removeTeacher(index: number) {
    this.getFormArray('teachers').removeAt(index);
    this.teachersOptions = this.teacherOptionsFilter();
    this.form.markAsDirty();
  }

  public getControl(control: string): AbstractControl {
    return this.form.controls[control];
  }

  public getFormArray(control: string): UntypedFormArray {
    return this.getControl(control) as UntypedFormArray;
  }

  public isRemoveTeacher(idx): boolean {
    return idx !== 0 || this.getFormArray('teachers').controls?.length > 1;
  }

  public isAddTeacher(idx): boolean {
    return this.showAddTeacher > this.getFormArray('teachers').controls?.length;
  }

  public onFileChanged(media_id: string) {
    this.form.patchValue({ media_url: media_id });
    logger.log('------onFileChanged', media_id, this.form);
  }

  public onRecordStarted(start: boolean) {
    logger.log('------onRecordStarted', start, this.form);
  }

  public isValid(formControlName: string): boolean {
    return this.getControl(formControlName).valid;
  }

  public isInvalid(formControlName: string): boolean {
    return this.displayInvalidForm && this.getControl(formControlName).invalid;
  }

  public stepNumber(step: stepType): number {
    switch (step) {
      case 'type':
        return 1;
      case 'props':
        return 2;
      case 'main':
        return 3;
      case 'teachers':
        return 4;
    }
    return 0;
  }

  public stepState(step: stepType): OnUiFormStepState {
    if (!this.displayInvalidForm) {
      return 'init';
    }

    switch (step) {
      case 'type':
        return this.isValid('type') ? 'success' : 'error';
      case 'teachers':
        return this.isValid('teachers') ? 'success' : 'error';
      case 'props':
        return this.isValid('meetings_limit') &&
          this.isValid('duration') &&
          this.isValid('price_tier') &&
          this.isValid('discount')
          ? 'success'
          : 'error';
      case 'main':
        return this.isValid('cover_id') &&
          this.isValid('subject_id') &&
          this.isValid('category_id') &&
          (this.getControl('participants_limit').disabled ? true : this.isValid('participants_limit')) &&
          this.isValid('age_from') &&
          this.isValid('age_to') &&
          this.isValid('level_id') &&
          this.isValid('title') &&
          this.isValid('description') &&
          this.isValid('language_id')
          ? 'success'
          : 'error';
    }
  }

  public get labelPeriod(): string {
    const type = this.getControl('type').value;

    switch (type) {
      case EUserServiceType.single:
        return 'user-service-form-3.period.per-class';
      case EUserServiceType.regular:
        return 'user-service-form-3.period.per-week';
      case EUserServiceType.mini:
        return 'user-service-form-3.period.for-class';
      case EUserServiceType.course:
        return 'user-service-form-3.period.for-class';
    }
  }

  public isCountClasses(): boolean {
    const type = this.getControl('type').value;

    switch (type) {
      case EUserServiceType.single:
      case EUserServiceType.regular:
        return false;
      case EUserServiceType.mini:
      case EUserServiceType.course:
        return true;
      default:
        return false;
    }
  }

  public get isWeekdays(): boolean {
    const type = this.getControl('type').value;
    const [single, group] = this.getControl('group').value;
    return group || type === EUserServiceType.mini || type === EUserServiceType.course;
  }

  public get isInvalidWeekdays(): boolean {
    const weekdays = this.weekdays
      .map((_day, index) => (this.form.value.weekdays[index] ? _day.value : null))
      .filter((_d) => _d != null);
    return this.displayInvalidForm && !weekdays.length;
  }

  public onSave() {
    this.displayInvalidForm = true;

    if (!this.form.valid || (this.isWeekdays && this.isInvalidWeekdays)) {
      this.setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
      this.detectChanges();
      return;
    }

    const { meetings_limit, age_from, age_to, teachers } = this.form.value;

    const weekdays = this.weekdays
      .map((_day, index) => (this.form.value.weekdays[index] ? _day.value : null))
      .filter((_d) => _d != null);
    const skills = this.form.value.skills.filter((_s) => _s);
    const requirements = this.form.value.requirements.filter((_r) => _r);
    const discount = this.form.value.discount >= 0 ? this.form.value.discount : null;
    const teacher_ids = teachers?.map((_t) => _t.id);

    const value = new UserService({
      ...this.form.value,
      school_id: this._userService?.school?.id,
      school: this._userService?.school,
      teacher_ids,
      discount,
      options: {
        requirements,
        skills,
        weekdays: this.isWeekdays ? weekdays : [],
        meetings_limit,
        age_from,
        age_to,
      },
    });

    logger.log('UserServiceForm3::onCreate::value', value);
    const observable: Observable<UserService> = this.isNew
      ? this.userServicesService.createUserService(value)
      : this.userServicesService.updateUserService(this.form.value.id, value);

    observable.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (result) => {
        this.changed.emit(result);
        this.form.markAsPristine();
        logger.log('UserServiceForm3::onCreate::successSave', result, value);
      },
      error: (error: any) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        logger.log('UserServiceForm3::onCreate::errorSave', error, value);
        this.detectChanges();
      },
    });
  }

  public get isSchool(): boolean {
    return !!this._userService?.school?.id;
  }

  public get isNew(): boolean {
    return !this._userService?.id;
  }

  public teachersTabs(): Array<Partial<User>> {
    const selectedSubjectId = this.getControl('subject_id').value;
    const teachers = this.getControl('teachers')
      .value.filter((_t) => !!_t)
      .filter((_tt) => _tt?.profile?.teaching_subjects.find((_ts) => _ts.subject_id === selectedSubjectId));
    return uniqBy([...teachers], 'id');
  }

  public isActiveVideo(tab: Partial<User>): boolean {
    return this.activeVideoTab?.id === tab.id;
  }

  public onActiveVideo(tab: Partial<User>) {
    this.activeVideoTab = tab;
    this.prepareMedia();
    this.detectChanges();
  }

  public videoTabLabel(tab: Partial<User>): string {
    return `${tab?.first_name?.substring(0, 1)}. ${tab?.last_name}`;
  }

  private prepareMedia() {
    const selectedSubjectId = this.getControl('subject_id').value;
    const teachingSubject = this.activeVideoTab.profile.teaching_subjects.find(
      (_ts) => _ts.subject_id === selectedSubjectId,
    );
    if (!teachingSubject || (!teachingSubject.video_url && !teachingSubject.video_id)) {
      this.safeMediaUrl = null;
      this.isMediaPrepared = false;
      return;
    }
    if (teachingSubject.video_id && teachingSubject.video_mode === ProfileMediaModeEnum.use_media_id) {
      this.filesService
        .getFileById(teachingSubject.video_id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (file) => {
            this.safeMediaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              file.media.find((_file) => _file.format === MediaFormatEnum.mp4)?.url,
            );
            this.isMediaPrepared = true;
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-FILE');
          },
        });
    } else if (teachingSubject.video_url && teachingSubject.video_mode === ProfileMediaModeEnum.use_media_url) {
      this.safeMediaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        youtubeEmbedUrl(teachingSubject.video_url) || vimeoEmbedUrl(teachingSubject.video_url),
      );
      this.isMediaPrepared = true;
    } else {
      this.safeMediaUrl = null;
      this.isMediaPrepared = false;
    }
  }

  public get showAddTeacher() {
    const selectedSubjectId = this.getControl('subject_id').value;
    return this._userService?.school?.participants
      .filter((_p) => _p.role === ESchoolParticipantRole.teacher)
      .map((_p) => _p.account)
      .filter((_tt) => _tt?.profile?.teaching_subjects.find((_ts) => _ts.subject_id === selectedSubjectId)).length;
  }

  private teacherOptionsFilter(): Array<Partial<User>> {
    const selectedSubjectId = this.getControl('subject_id').value;
    return this._userService?.school?.participants
      .filter((_p) => _p.role === ESchoolParticipantRole.teacher)
      .map((_p) => _p.account)
      .filter((_tt) => _tt?.profile?.teaching_subjects.find((_ts) => _ts.subject_id === selectedSubjectId))
      .filter((_tt) => {
        const teacherAlreadySelected = this.getFormArray('teachers')['value'].find((val) => val?.id === _tt.id);
        return !teacherAlreadySelected;
      });
  }

  private static requirementFormControl(value: string = null): AbstractControl {
    return new UntypedFormControl(value);
  }

  private static skillFormControl(value: string = null): AbstractControl {
    return new UntypedFormControl(value);
  }

  private static mediaFormControl(value: string = null): AbstractControl {
    return new UntypedFormControl(value);
  }

  private static weekDaysFormControl(value = false): AbstractControl {
    return new UntypedFormControl(value);
  }

  private static teacherFormControl(value: Partial<User> = null): AbstractControl {
    return new UntypedFormControl(value, [Validators.required]);
  }

  private setLanguage() {
    const [first, ...others] = this.languagesOptions;
    this.form.patchValue({ language_id: first?.value });
  }

  private generateBeginAgeOptions() {
    this.beginAgeOptions = Array.from({ length: 66 }, (v, i) => i + 1).map((item) => ({
      value: item,
      title: item === 66 ? '65+' : item.toString(),
    }));
  }

  private generateEndAgeOptions() {
    const from = this.getControl('age_from').value || 66;
    this.endAgeOptions = Array.from({ length: from === 66 ? from : 66 - from }, (v, i) =>
      from === 66 ? i + 1 : i + from + 1,
    ).map((item) => ({ value: item, title: item === 66 ? '65+' : item.toString() }));
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      title: new UntypedFormControl('', [Validators.required, Validators.maxLength(90)]),
      group: new UntypedFormArray([
        UserServiceForm3Component.weekDaysFormControl(true),
        UserServiceForm3Component.weekDaysFormControl(),
      ]),
      type: new UntypedFormControl(EUserServiceType.single, Validators.required),
      cover_id: new UntypedFormControl(''),
      subject_id: new UntypedFormControl('', Validators.required),
      subject_other_id: new UntypedFormControl(null),
      category_id: new UntypedFormControl(null, Validators.required),
      participants_limit: new UntypedFormControl({ value: 1, disabled: true }, Validators.required),
      level_id: new UntypedFormControl(null),
      level_other_name: new UntypedFormControl(null),
      description: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(100),
        Validators.maxLength(2000),
      ]),
      language_id: new UntypedFormControl(null, Validators.required),
      price_tier: new UntypedFormControl('', Validators.required),
      duration: new UntypedFormControl(null, Validators.required),
      discount: new UntypedFormControl(null),
      media: new UntypedFormArray([UserServiceForm3Component.mediaFormControl()]),
      // options
      requirements: new UntypedFormArray([UserServiceForm3Component.requirementFormControl()]),
      skills: new UntypedFormArray([UserServiceForm3Component.skillFormControl()]),
      weekdays: new UntypedFormArray(this.weekdays.map(() => UserServiceForm3Component.weekDaysFormControl())),
      meetings_limit: new UntypedFormControl(null, Validators.required),
      age_from: new UntypedFormControl(null, Validators.required),
      age_to: new UntypedFormControl(null, Validators.required),
      media_url: new UntypedFormControl(''),
    });
  }

  private addTeachersControls() {
    this.form.addControl(
      'teachers',
      new UntypedFormArray([UserServiceForm3Component.teacherFormControl()], this.hasDuplicate.bind(this, 'id')),
    );
  }

  private hasDuplicate(key: string = null, formArray: UntypedFormArray): ValidationErrors {
    const values = formArray.controls
      .map((_control) => _control.value)
      .map((_v) => (key && _v !== null && _v.hasOwnProperty(key) ? _v[key] : _v));
    const hasDuplicate = values.some((name, index) => values.indexOf(name, index + 1) != -1);
    return hasDuplicate ? { duplicate: true } : null;
  }

  private loadModel() {
    logger.log('LOad model', this._userService);

    if (!this._userService) {
      return;
    }

    const {
      id,
      title,
      type,
      cover_id,
      subject_other_id,
      category_id,
      participants_limit,
      level_id,
      description,
      language_id,
      price_tier,
      duration,
      discount,
      media,
      options,
      media_url,
      teachers,
    } = this._userService;

    const subject_id =
      this._userService.subject_id === otherSubjectId
        ? this._userService.subject_other_id
        : this._userService.subject_id;

    const weekdays = this.weekdays?.map((day) => options?.weekdays?.findIndex((_w) => _w === day.value) >= 0);
    options?.skills?.map((_skill, index) => this.addSkill());
    options?.requirements?.map((_skill, index) => this.addRequirement());

    const group = [participants_limit === 1, participants_limit > 1];

    this.setSubjectLevelsBySubject(subject_id as number);

    this._userService?.teachers?.forEach((v, i) => (i === 0 ? null : this.addTeacher()));

    const value = {
      id,
      title,
      group,
      type,
      cover_id,
      subject_id,
      subject_other_id,
      category_id,
      participants_limit,
      level_id,
      description,
      language_id,
      price_tier,
      duration,
      discount,
      media,
      ...options,
      weekdays,
      media_url: media_url || '',
      teachers,
    };
    logger.log('Patch form', value);
    this.form.patchValue(value);

    if (value.group && value.group[0]) {
      this.participantsLimitOptions = participantsSingleLimit;
      this.getControl('participants_limit').disable();
    } else {
      this.participantsLimitOptions = participantsGroupLimit;
      this.getControl('participants_limit').enable();
    }

    this.form.markAsPristine();
  }

  private prepareTeachingSubjectsOptions() {
    if (!this._subjects?.length || !this._teachingSubjects?.length) {
      return;
    }

    this.teachingSubjectsOptions = this._teachingSubjects.map((x) => {
      const subjectsTeaching = this._subjects.filter(
        (_s) => !!this._teachingSubjects.find((_ts) => _ts.subject_id === _s.id),
      );

      const nativeSubjects = subjectsTeaching.filter((_s) => _s.category_id === SubjectCategoryEnum.native_language);

      const isNative = !!nativeSubjects.find((_s) => +_s.id === +x.subject_id);

      const foreignSubjects = subjectsTeaching.filter((_s) => _s.category_id === SubjectCategoryEnum.foreign_language);

      const isForeign = !!foreignSubjects.find((_s) => +_s.id === +x.subject_id);

      return {
        value: x.subject_other_id || x.subject_id,
        title: x.subject_other_name || this._subjects.find((s) => s.id === x.subject_id).title,
        native: isNative,
        foreign: isForeign,
      };
    });
  }

  private prepareLanguagesOptions() {
    const spokenLanguages = this._me?.spoken_languages || [];
    this.languagesOptions = this._languages
      .filter((_lang) => !!spokenLanguages.find((_l) => +_l.language_id === +_lang.id))
      .map((_l) => ({
        value: _l.id,
        title: _l.title,
      }));
  }

  private preparePriceOptions() {
    if (this.currencies) {
      let selectedCurrency =
        Object.keys(CurrencyEnum)[Object.values(CurrencyEnum).indexOf(this._me.currency_id)].toUpperCase();
      if (selectedCurrency === 'RUR') {
        selectedCurrency = 'RUB';
      }
      const rate = this.getRateForCurrency(this.currencies, selectedCurrency);
      if (rate) {
        this.priceOptions = this._billingSettings.prices.map((price_tier) => ({
          value: price_tier.id,
          sub_title:
            '<span class="sub_value">(≈' +
            (price_tier.amount * rate.rate).toFixed(rate.fraction) +
            ' ' +
            rate.currency +
            ')</span>',
          title: this.billingService.formatPriceTier(price_tier),
          price_tier,
        }));
      } else {
        this.priceOptions = this._billingSettings.prices.map((price_tier) => ({
          value: price_tier.id,
          title: this.billingService.formatPriceTier(price_tier),
          price_tier,
        }));
      }
    } else {
      this.priceOptions = this._billingSettings.prices.map((price_tier) => ({
        value: price_tier.id,
        title: this.billingService.formatPriceTier(price_tier),
        price_tier,
      }));
    }
  }

  public getRateForCurrency(
    currencies: BillingApiCurrencyRateResponseDto[],
    currencyCode: string,
  ): BillingApiCurrencyRateResponseDto {
    if (!currencies.length) {
      return;
    }
    return currencies.find((currency) => currency.currency === currencyCode.toUpperCase());
  }

  private get _subjects(): BaseSubject[] {
    return [
      ...this._subjectRegistry.native,
      ...this._subjectRegistry.foreign,
      ...this._subjectRegistry.other,
      ...this._subjectRegistry.non_categorized,
      ...this._subjectRegistry.user,
    ];
  }

  private findSubjectById(subject_id: number): BaseSubject {
    if (!this._subjectRegistry) {
      return null;
    }
    return this._subjects.find((_s) => _s.id === subject_id) || null;
  }

  private setSubjectLevelsBySubject(subject_id: number) {
    const subject = this.findSubjectById(subject_id);
    const _subjectLevels: SubjectLevel[] =
      subject && subject.levels_category_id ? this._subjectLevelsRegistry[subject.levels_category_id] || [] : [];

    const otherSubjectLevel = new SubjectLevel({
      id: otherSubjectLevelId,
      code: '',
      title: 'Other...', // TODO this.translate.instant('????'),
    });

    this.subjectLevelOptions = [..._subjectLevels].map((x) => ({
      value: x.id,
      title: x.title,
    }));
  }

  private loadProfiles() {
    const accountsFilter: Partial<IFindAccountsFilter> = {
      id: this._userService?.school.participants.map((_p) => _p.account.id),
    };
    const pagination = {
      page: 1,
      pageSize: 100,
    };
    const details: AccountDetailsType[] = ['id', 'profile'];

    this.accountService
      .findAccounts(accountsFilter, pagination, details)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (users) => {
          this._userService?.school.participants.map((_p) => {
            const profile = users.results.find((_u) => _u.id === _p.account.id);
            if (profile) {
              _p.account.profile = profile.profile;
            }
            return _p;
          });
          this.teachersOptions = this.teacherOptionsFilter();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private scrollToFirstInvalidControl() {
    const form = document.getElementsByClassName('user-service-form')[0];
    const firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  public setTutors(): void {
    this.router.navigate([
      SchoolsService.schoolRoute(this._userService?.school).slice(1).join('/') + '/settings/set-teacher',
    ]);
  }

  public toggleShowMore() {
    this.showMore = !this.showMore;
  }

  public trackByFn(index) {
    return index;
  }
}
