import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AttachmentUploaderModule } from '@app/account/teacher-page/user-service-form-3/attachment-uploader/attachment-uploader.module';
import { FormControlsModule, RecordVideoModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import {
  OnUiBulletListModule,
  OnUiButtonModule,
  OnUiButtonTabModule,
  OnUiFormFieldErrorModule,
  OnUiFormStepModule,
  OnUiInputModule,
  OnUiRadioModule,
  OnUiSelectModule,
  OnUiTextareaModule,
} from 'onclass-ui';
import { TeacherSelectorModule } from './teacher-selector/teacher-selector.module';
import { UserServiceForm3Component } from './user-service-form-3.component';
import {IconComponent} from "@shared/icon/icon.component";

@NgModule({
  declarations: [UserServiceForm3Component],
  exports: [UserServiceForm3Component],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        OnUiBulletListModule,
        OnUiSelectModule,
        OnUiRadioModule,
        OnUiInputModule,
        FormControlsModule,
        OnUiButtonModule,
        AttachmentUploaderModule,
        OnUiFormStepModule,
        OnUiButtonTabModule,
        RecordVideoModule,
        OnUiFormFieldErrorModule,
        OnUiTextareaModule,
        RouterModule,
        TeacherSelectorModule,
        IconComponent,
    ],
})
export class UserServiceForm3Module {}
