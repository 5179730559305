import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { OnUiProgressBarModule } from 'onclass-ui';
import { AttachmentUploaderComponent } from './attachment-uploader.component';
import {IconComponent} from "@shared/icon/icon.component";

@NgModule({
  declarations: [AttachmentUploaderComponent],
  exports: [AttachmentUploaderComponent],
    imports: [CommonModule, DropzoneModule, SpinnersModule, OnUiProgressBarModule, IconComponent],
})
export class AttachmentUploaderModule {}
