<footer class="footer">
  <ng-container *ngFor="let item of menuItems; trackBy: trackByFn">
    <a *ngIf="item.url"
      class="nav-item"
      itemprop="url"
      [class.disable-active]="isChatExpanded"
      [routerLink]="item.id === 'class' ? null : item.url"
      routerLinkActive="active"
      (click)="item.id === 'class' ? toggleCreateModal() : closeChat($event)">
      <app-icon class="nav-item-icon" [ngClass]="item.id" file="header" [icon]="item.icon"></app-icon>
      {{ item.label | translate }}
    </a>
  </ng-container>
  <div class="nav-item chat-item" *ngIf="isBrowser && me && !isGuest && !isLimitedVersion"
       [class.active]="isChatExpanded" (click)="toggleChat()">
    <span class="chat-icon-wrapper">
      <div class="chat-unread-count" *ngIf="unreadCount > 0">{{ unreadCount }}</div>
      <app-icon class="nav-item-icon chat" icon="chat"/>
      {{ 'menu.top.chat' | translate }}
    </span>
  </div>
  <div *ngIf="showCreatePopup" class="event-create-popup">
    <div class="options-block">
      <div class="option blue" *ngIf="quickMeetingVisible" (click)="onQuickMeeting()">
        <div class="icon">
          <app-icon width="30" height="30" file="sidebar" icon="sidebar-quick-class"></app-icon>
        </div>
        <p class="label">{{'menu.left-sidebar.study-create' | translate}}</p>
      </div>
      <div class="option" *ngIf="scheduleMeetingVisible" (click)="onScheduleMeeting()">
        <div class="icon">
          <app-icon width="30" height="30" file="sidebar" icon="sidebar-schedule-class"></app-icon>
        </div>
        <p class="label">{{'my-classes.actions.schedule-class' | translate}}</p>
      </div>
    </div>
  </div>
</footer>

<div *ngIf="showCreatePopup" (click)="showCreatePopup=false" class="shade"></div>

<ng-template [ngIf]="meetingFormOpened">
  <app-modal *ngIf="!deviceService.isMobile()" (closed)="closeMeetingForm()">
    <app-modal-body>
      <app-meeting-form-wrapper (saved)="closeMeetingForm()" [caller]="'app-footer'"></app-meeting-form-wrapper>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeMeetingForm()">
    <app-mobile-modal-title>{{ 'lingo2-forms.meetings.form-title' | translate }}</app-mobile-modal-title>
    <app-mobile-modal-body>
      <app-meeting-form-wrapper (saved)="closeMeetingForm()" [caller]="'app-footer'"></app-meeting-form-wrapper>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>

