<div *ngIf="!mini; else miniTemplate" [class.no-plan-banner]="!activePlan" class="banner-plan">
  <ng-template [ngIf]="activePlan">
    <div (click)="buyOrUpgradePlan()" class="cover-card"></div>
    <ng-container *ngTemplateOutlet="notActiveSubscriptionContent"></ng-container>
    <div class="plan-info">
      <div class="icon-wrap">
        <app-icon width="38" height="38" [ngStyle]="{ 'fill' : activePlan?.plan?.details?.color }" icon="finance-plan"></app-icon>
      </div>
      <ng-container *ngTemplateOutlet="meta"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="actionContent"></ng-container>
  </ng-template>

  <ng-template [ngIf]="!activePlan">
    <div class="no-plan-meta">
      <div class="icon-wrap">
        <app-icon width="38" height="38" file="finance-plan" icon="finance-plan-none"></app-icon>
      </div>
      <p>{{ 'subscription-plans.plan-not-selected' | translate }}</p>
    </div>
    <div class="no-plan-action">
      <on-ui-button (onclick)="buyOrUpgradePlan()" [label]="'subscription-plans.choose-plan'" class="primary"></on-ui-button>
    </div>
  </ng-template>
</div>

<ng-template #miniTemplate>
  <div
    [appPopover]="deviceService.isDesktop() ? popoverContent : null"
    [close]="closePopover"
    [isHover]="deviceService.isDesktop()"
    [popoverOnly]="true"
    class="banner-plan-mini"
    position="right">
    <div (click)="deviceService.isDesktop() ? null : buyOrUpgradePlan()" class="cover"></div>
    <app-icon width="61" height="61" [ngStyle]="{ 'fill' : activePlan?.plan?.details?.color }"
              file="finance-plan" [icon]="activePlan ? 'finance-plan' : 'finance-plan-none'">
    </app-icon>
  </div>
</ng-template>

<ng-template #meta>
  <div class="meta">
    <span class="title">{{ 'banner-plan.title' | translate }}</span>
    <span class="plan-title">{{ activePlan?.plan?.details?.title | translate }}</span>
    <span (click)="openBuyCreditsModal()" class="credit-label" *ngIf="!isNotActiveSubscription">
      {{ 'banner-plan.credit-label' | translate }}
      <span class="credit-value">{{ creditsAmount }}</span>
    </span>
    <span class="date-label">
      {{ 'banner-plan.expired-at' | translate }}
      <span class="date-value">{{ expiredDate | date:"dd.MM.yyyy"  }}</span>
    </span>
    <span *ngIf="activePlan?.next_plan_id" (click)="buyOrUpgradePlan()" class="action">{{ 'banner-plan.action' | translate }}</span>
  </div>
</ng-template>

<ng-template #popoverContent>
  <div class="popover">
    <ng-template [ngIf]="activePlan">
      <div (click)="buyOrUpgradePlan()" class="cover-card"></div>
      <ng-container *ngTemplateOutlet="notActiveSubscriptionContent"></ng-container>
      <ng-container *ngTemplateOutlet="meta"></ng-container>
      <ng-container *ngTemplateOutlet="actionContent"></ng-container>
    </ng-template>

    <ng-template [ngIf]="!activePlan">
      <p>{{ 'subscription-plans.plan-not-selected' | translate }}</p>
      <div class="no-plan-action">
        <on-ui-button (onclick)="buyOrUpgradePlan()" [label]="'subscription-plans.choose-plan'" class="primary"></on-ui-button>
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #notActiveSubscriptionContent>
  <div *ngIf="isNotActiveSubscription" class="not-active-subscription-wrap" [class.mini]="mini">
    <div class="icon-wrap">
      <app-icon width="25" height="25" icon="billing-failed"></app-icon>
    </div>

    <div class="plan-text-wrap">
      <span class="next-plan-text">{{'subscription-plans.plan-inactive' | translate}}</span>
    </div>
  </div>
</ng-template>

<ng-template #actionContent>
  <div *ngIf="isNotActiveSubscription">
    <on-ui-button [class]="'secondary'"
                  [label]="'subscription-plans.resume'"
                  (onclick)="toggleResumePlan()">
    </on-ui-button>
  </div>
</ng-template>

<app-finance-plan-buy-credits-form-v2
  *ngIf="buyCreditsModalOpened"
  (activatePlan)="getPlanForCredits()"
  (close)="closeBuyCreditsModal()"
  (success)="closeBuyCreditsModal()"
  [plan]="activePlan?.plan">
</app-finance-plan-buy-credits-form-v2>

<ng-template [ngIf]="openResumePlanModal">
  <app-payment-wizard-dialog [plan]="activePlan" (closed)="toggleResumePlan()"></app-payment-wizard-dialog>
</ng-template>
