<div class="attachment-uploader">
  <div class="dropzone dropzone-container"
       [dropzone]="dropzoneConfig"
       (init)="onUploadInit($event)"
       (uploadProgress)="onUploadProgress($event)"
       (sending)="onUploadStart($event)"
       (error)="onUploadError($event)"
       (success)="onUploadSuccess($event)">
    <div class="dz-default dz-message">
      <ng-template [ngIf]="cover && !cover_uploading">
        <img [src]="cover.url" alt="" class="cover-img">
      </ng-template>

      <ng-template [ngIf]="!cover_uploading && !cover">
        <app-icon class="attach-icon" file="upload" icon="attach-placeholder"></app-icon>
      </ng-template>

      <ng-template [ngIf]="cover_uploading">
        <spinner1></spinner1>
      </ng-template>
    </div>
  </div>

  <ng-template [ngIf]="cover && !cover_uploading">
    <div class="removed-action" (click)="onRemoved($event)">
      <div class="removed-icon">
        <app-icon width="19" height="18" icon="close"></app-icon>
      </div>
    </div>
  </ng-template>
</div>

<on-ui-progress-bar [value]="percent"></on-ui-progress-bar>

