<ng-template [ngIf]="deviceService.isDesktop()" [ngIfElse]="mobile">
  <article [formGroup]="form"
           class="school-form"
           [class.modal]="modalDisplayMode">
    <h2 class="title bold" *ngIf="!fromSettings">{{ 'school-form.header' | translate }}</h2>

    <div class="row margin-bottom">
      <div class="col-210">
        <div class="row">
          <div class="step normal"
               [class.invalid]="isInvalid('teaching_subjects')"
               [class.valid]="isValid('teaching_subjects')">1
          </div>
          <span class="step-label bold">{{ 'school-form.step-title.subjects' | translate }}</span>
        </div>
        <div class="tail"></div>
      </div>
      <div class="col" formArrayName="teaching_subjects">
        <div *ngFor="let subject of teachingSubjects.controls; let idx = index; trackBy: trackByFn"
             class="row margin-bottom">
          <div class="col-275">
            <app-subject-selector [subjects]="subjectsRegistry"
                                  [subjectSelectedId]="subject?.value"
                                  [valid]="!isInvalid('teaching_subjects')"
                                  (selectSubjectId)="selectSubjectId($event, idx)">
            </app-subject-selector>
          </div>
          <ng-template [ngIf]="teachingSubjects.controls.length > 1">
            <div class="action" (click)="removeSubject(idx)">
              <app-icon class="trash-icon" width="30" height="30" file="basket" icon="basket-3"></app-icon>
            </div>
          </ng-template>
          <div class="action" (click)="addSubject()">
            <app-icon width="16" height="16" file="plus" icon="plus-2"></app-icon>
          </div>
        </div>
        <p class="error-text" *ngIf="isInvalid('teaching_subjects')">{{'error.not-chosen' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom-20">
      <div class="col-285">
        <div class="row">
          <div class="step normal"
               [class.invalid]="isInvalidGroup()"
               [class.valid]="isValidGroup()">2
          </div>
          <span class="step-label bold">{{ 'school-form.step-title.school-info' | translate }}</span>
        </div>
      </div>
    </div>

    <div class="row margin-bottom-20">
      <div class="col">
        <app-image-uploader formControlName="head_cover_id"
                            [error]="isInvalid('head_cover_id')"
                            [display]="'high'"
                            [autoCrop]="true"
                            [cropRatio]="'wide-banner'">
        </app-image-uploader>
        <p class="error-text" *ngIf="isInvalid('head_cover_id')">{{'error.required' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom-20">
      <div class="col-210">
        <app-image-uploader formControlName="cover_id"
                            [error]="isInvalid('cover_id')">
        </app-image-uploader>
        <p class="error-text" *ngIf="isInvalid('cover_id')">{{'error.required' | translate}}</p>
      </div>
      <div class="col">
        <div class="row margin-bottom">
          <div class="col">
            <app-form-field class="field" [control]="form.controls.title" [hasDisplayErrorText]="isInvalid('title')">
              <on-ui-input [placeholder]="'user-service-form-2.name-placeholder' | translate"
                           [maxlength]="90"
                           [hasCounter]="true"
                           [error]="isInvalid('title')"
                           (changed)="onChangedTitle($event)"
                           formControlName="title">
              </on-ui-input>
            </app-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col short_description">
            <app-form-field class="field" [control]="form.controls.short_description"
                            [hasDisplayErrorText]="isInvalid('short_description')">
              <on-ui-textarea formControlName="short_description"
                              [maxlength]="100"
                              [placeholder]="'Краткое описание'"
                              [error]="isInvalid('short_description')"
                              (changed)="onChangedDescription($event)">
              </on-ui-textarea>
            </app-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-form-field [control]="form.controls.description" [hasDisplayErrorText]="isInvalid('description')">
          <on-ui-textarea formControlName="description"
                          [error]="isInvalid('description')"
                          (changed)="onChangedDescription($event)">
          </on-ui-textarea>
        </app-form-field>
      </div>
    </div>

    <div class="row margin-bottom">
      <span class="step-label bold">{{ 'school-form.step-title.language' | translate }}</span>
    </div>

    <div class="row margin-bottom-20">
      <div class="col" formArrayName="teaching_languages">
        <div *ngFor="let language of teachingLanguages.controls; let idx = index; trackBy: trackByFn"
             class="row margin-bottom">
          <div class="col-260">
            <on-ui-autocomplete [error]="isInvalid('teaching_languages')"
                              [placeholder]="'school-form.message2' | translate"
                              [options]="languagesOptions"
                              [formControlName]="idx"
                              (changed)="selectLanguageId($event, idx)">
            </on-ui-autocomplete>
          </div>
          <ng-template [ngIf]="teachingLanguages.controls.length > 1">
            <div class="action" (click)="removeLanguage(idx)">
              <app-icon class="trash-icon" width="30" height="30" file="basket" icon="basket-3"></app-icon>
            </div>
          </ng-template>
          <div class="action" (click)="addLanguage()">
            <app-icon width="16" height="16" file="plus" icon="plus-2"></app-icon>
          </div>
        </div>
        <p class="error-text" *ngIf="isInvalid('teaching_languages')">{{'error.not-chosen' | translate}}</p>
      </div>
    </div>

    <div class="row h-right">
      <on-ui-button [ngClass]="fromSettings ? 'secondary' : 'primary'"
                    [state]="savedActionState"
                    [label]="fromSettings || school?.id ? 'classrooms.form.actions.save' : 'school-form.action'"
                    (onclick)="onSave()">
      </on-ui-button>
    </div>
  </article>
</ng-template>

<ng-template #mobile>
  <article [formGroup]="form"
           class="school-form mobile">
    <div class="row margin-bottom">
      <div class="step normal"
           [class.invalid]="isInvalid('teaching_subjects')"
           [class.valid]="isValid('teaching_subjects')">1
      </div>
      <span class="step-label bold">{{ 'school-form.step-title.subjects' | translate }}</span>
    </div>

    <div class="row">
      <div class="col" formArrayName="teaching_subjects">
        <div *ngFor="let subject of teachingSubjects.controls; let idx = index; trackBy: trackByFn"
             class="row margin-bottom">
          <div class="col-260">
            <app-subject-selector [subjects]="subjectsRegistry"
                                  [subjectSelectedId]="subject?.value"
                                  [valid]="!isInvalid('teaching_subjects')"
                                  (selectSubjectId)="selectSubjectId($event, idx)">
            </app-subject-selector>
          </div>
          <ng-template [ngIf]="teachingSubjects.controls.length > 1">
            <div class="action" (click)="removeSubject(idx)">
              <app-icon class="trash-icon" width="30" height="30" file="basket" icon="basket-3"></app-icon>
            </div>
          </ng-template>
          <div class="action" (click)="addSubject()">
            <app-icon width="16" height="16" file="plus" icon="plus-2"></app-icon>
          </div>
        </div>
        <p class="error-text move-top-6" *ngIf="isInvalid('teaching_subjects')">{{'error.not-chosen' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom-20">
      <div class="step normal"
           [class.invalid]="isInvalidGroup()"
           [class.valid]="isValidGroup()">2
      </div>
      <span class="step-label bold">{{ 'school-form.step-title.school-info' | translate }}</span>
    </div>

    <div class="row margin-bottom-20">
      <div class="col">
        <app-image-uploader class="long"
                            formControlName="head_cover_id"
                            [display]="'high'"
                            [autoCrop]="true"
                            [cropRatio]="'wide-banner'"
                            [error]="isInvalid('head_cover_id')">
        </app-image-uploader>
        <p class="error-text" *ngIf="isInvalid('head_cover_id')">{{'error.required' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom-20 h-center">
      <app-image-uploader formControlName="cover_id"
                          [error]="isInvalid('cover_id')">
      </app-image-uploader>
    </div>

    <div class="row margin-bottom">
      <div class="col">
        <on-ui-input [placeholder]="'user-service-form-2.name-placeholder' | translate"
                     [maxlength]="50"
                     [hasCounter]="true"
                     [error]="isInvalid('title')"
                     (changed)="onChangedTitle($event)"
                     formControlName="title">
        </on-ui-input>
        <p class="error-text" *ngIf="isInvalid('title')">{{'school-form.message1' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col short_description">
        <on-ui-textarea formControlName="short_description"
                        [maxlength]="100"
                        [placeholder]="'Краткое описание'"
                        [error]="isInvalid('short_description')"
                        (changed)="onChangedDescription($event)">
        </on-ui-textarea>
      </div>
    </div>

    <div class="row margin-bottom">
      <div class="col">
        <on-ui-textarea formControlName="description"
                        [error]="isInvalid('description')"
                        (changed)="onChangedDescription($event)">
        </on-ui-textarea>
      </div>
    </div>

    <div class="row margin-bottom">
      <span class="step-label bold">{{ 'school-form.step-title.language' | translate }}</span>
    </div>

    <div class="row margin-bottom-20">
      <div class="col" formArrayName="teaching_languages">
        <div *ngFor="let language of teachingLanguages.controls; let idx = index; trackBy: trackByFn"
             class="row margin-bottom">
          <div class="col-260">
            <on-ui-autocomplete [error]="isInvalid('teaching_languages')"
                              [placeholder]="'school-form.message2' | translate"
                              [options]="languagesOptions"
                              [formControlName]="idx"
                              (changed)="selectLanguageId($event, idx)">
            </on-ui-autocomplete>
          </div>
          <ng-template [ngIf]="teachingLanguages.controls.length > 1">
            <div class="action" (click)="removeLanguage(idx)">
              <app-icon class="trash-icon" width="30" height="30" file="basket" icon="basket-3"></app-icon>
            </div>
          </ng-template>
          <div class="action" (click)="addLanguage()">
            <app-icon width="16" height="16" file="plus" icon="plus-2"></app-icon>
          </div>
        </div>
        <p class="error-text move-top-6" *ngIf="isInvalid('teaching_languages')">{{'school-form.message' | translate}}</p>
      </div>
    </div>

    <div class="row margin-bottom-20">
      <div class="col">
        <on-ui-button class="primary"
                      [state]="savedActionState"
                      [label]="fromSettings || school?.id ? 'classrooms.form.actions.save' : 'school-form.action'"
                      (onclick)="onSave()">
        </on-ui-button>
      </div>
    </div>
  </article>
</ng-template>

<pre *ngIf="(debug$ | async)" style="background: #eee; max-height: 250px; overflow: auto;">{{ form.value | json }}</pre>
