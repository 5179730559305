import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ContextService, PlatformService } from '@core/services';
import { RedPointsService } from '@core/services/red-points.service';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { setRedPoints } from '@store/actions/profile.actions';
import { ERedPointsEvents, IRedPointsEvents } from '@store/models';
import { getRedPoints } from '@store/reducers/profile.reducer';
import {
  GenericNoticeEvent,
  IAccountUpdate,
  IEntityUpdate,
  INoticeParams,
  IRedPoint,
  RedPointTypeEnum,
} from 'lingo2-models';
import { takeUntil } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-red-points',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './red-points.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedPointsComponent extends ChangableComponent implements OnInit {
  public debug$ = this.contextService.debug$;
  /** @debug */
  private state = false;

  private _redPointsEvents: IRedPointsEvents;
  private _iRedPoint: IRedPoint[];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private redPointsService: RedPointsService,
    private store: Store,
    private router: Router,
    private contextService: ContextService,
    private websocketService: WebsocketService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.loadRedPoints();
    }

    this.websocketService
      .on<IEntityUpdate>('update')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (event) => {
          switch (event.entity) {
            case 'schedule':
              this.store.dispatch(
                setRedPoints({ points: { ...this._redPointsEvents, [ERedPointsEvents.update_schedule]: true } }),
              );
              break;
            case 'meeting':
              break;
            case 'content':
              break;
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    this.websocketService
      .on<GenericNoticeEvent<INoticeParams>>('notice')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (notice) => {
          let result: IRedPointsEvents = this._redPointsEvents;

          this.setTimeout(() => {
            switch (notice.notice.title) {
              case 'classroom_new_invitation':
                result = { ...result, [ERedPointsEvents.classroom_new_invitation]: true };
                break;
              case 'content_comment':
                result = { ...result, [ERedPointsEvents.content_comment]: true };
                break;
            }
            this.store.dispatch(setRedPoints({ points: result }));
          }, 1000);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    this.websocketService
      .on<IAccountUpdate>('account')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    this.websocketService
      .on<GenericNoticeEvent<never>>('redpoints-updated')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (notice) => {
          this.loadRedPoints();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    this.store
      .select(getRedPoints)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (redPointsEvents) => {
          this._redPointsEvents = redPointsEvents;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (event) => {
        if (event instanceof NavigationEnd) {
          let result: IRedPointsEvents = this._redPointsEvents;

          this.setTimeout(() => {
            switch (event.url) {
              case '/my-classes/events':
                result = {
                  ...result,
                  [ERedPointsEvents.update_schedule]: false,
                };
                break;
              case '/my-classes/classrooms':
                result = {
                  ...result,
                  [ERedPointsEvents.classroom_new_invitation]: false,
                };
                break;
              case '/comments':
                result = {
                  ...result,
                  [ERedPointsEvents.content_comment]: false,
                };
                break;
              case '/favorites/lessons':
                this.removeRedPoint(RedPointTypeEnum.bookmark, result.bookmark);
                result = {
                  ...result,
                  [ERedPointsEvents.bookmark]: false,
                };
                break;
            }
            this.store.dispatch(setRedPoints({ points: { ...result } }));
          }, 1000);
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      }
    });
  }

  public setPoint(eventName) {
    this.store.dispatch(setRedPoints({ points: { ...this._redPointsEvents, [eventName]: this.state } }));
    this.state = !this.state;
  }

  public allPoint() {
    const value = { ...this._redPointsEvents };
    Object.keys(this._redPointsEvents).map((k) => (value[k] = this.state));
    this.store.dispatch(setRedPoints({ points: value }));
    this.state = !this.state;
  }

  private setRedPoints(type: RedPointTypeEnum) {
    let result: IRedPointsEvents = this._redPointsEvents;
    switch (type) {
      case RedPointTypeEnum.my_lesson_moderation:
        result = { ...result, [ERedPointsEvents.my_lesson_moderation]: true };
        break;
      case RedPointTypeEnum.my_lesson_published:
        result = { ...result, [ERedPointsEvents.my_lesson_published]: true };
        break;
      case RedPointTypeEnum.my_collection_moderation:
        result = { ...result, [ERedPointsEvents.my_collection_moderation]: true };
        break;
      case RedPointTypeEnum.my_collection_published:
        result = { ...result, [ERedPointsEvents.my_collection_published]: true };
        break;
      case RedPointTypeEnum.my_user_service_moderation:
        result = { ...result, [ERedPointsEvents.my_user_service_moderation]: true };
        break;
      case RedPointTypeEnum.my_user_service_published:
        result = { ...result, [ERedPointsEvents.my_user_service_published]: true };
        break;
      case RedPointTypeEnum.content_commented:
        result = { ...result, [ERedPointsEvents.content_commented]: true };
        break;
      case RedPointTypeEnum.content_published:
        result = { ...result, [ERedPointsEvents.content_published]: true };
        break;
      case RedPointTypeEnum.collection_purchased:
        result = { ...result, [ERedPointsEvents.collection_purchased]: true };
        break;
      case RedPointTypeEnum.bookmark:
        result = { ...result, [ERedPointsEvents.bookmark]: true };
        break;
    }
    this.store.dispatch(setRedPoints({ points: result }));
    this.detectChanges();
  }

  private removeRedPoint(redPoint: RedPointTypeEnum, check: boolean) {
    if (!check) {
      return;
    }
    this.redPointsService
      .removeRedPoint(redPoint)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });
  }

  private loadRedPoints() {
    this.redPointsService
      .getRedPoints()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (redPoints) => {
          this._iRedPoint = redPoints;
          this._iRedPoint.map((_rp) => this.setRedPoints(_rp.type));
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });
  }
}
