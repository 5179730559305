@import '../../../../core/scss/variables';
@import '../../../../core/scss/mixins';

.teacher-selector {
  position: relative;
  display: block;
  width: 100%;

  .teacher-selector-select {
    height: 60px;
    background: transparent;
    border: 1px solid $gray02;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    transition: border-color .2s linear;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    padding: 5px 35px 5px 10px;

    .placeholder {
      @include text();
      color: $gray03;
      display: inline-flex;
      align-items: center;
      height: 50px;
      padding: 0 10px;
    }

    &:hover {
      .teacher-option {
        background: transparent;
      }
    }
  }

  .teacher-selector-chevron {
    stroke: $gray04;
    position: absolute;
    right: 20px;
    top: 28px;
    transform: rotate(180deg);
    transition: all .2s linear;
  }

  .teacher-selector-dropdown {
    @include scrollbars(.5em, $gray02);
    border-radius: 10px;
    box-shadow: $shadow-card;
    position: absolute;
    background: $white;
    cursor: pointer;
    width: 100%;
    right: 0;
    margin-top: 5px;
    max-height: 280px;
    overflow-y: auto;
    z-index: 1;
    padding: 10px 0;
  }

  &.opened {
    .teacher-selector-select {
      border: 1px solid $sky;
    }

    .teacher-selector-chevron {
      transform: rotate(0);
      transition: all $transition-time linear;
    }
  }

  &.error {
    .teacher-selector-select {
      border: 1px solid $tomato;
    }
  }
}

.teacher-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 10px;
  transition: all $transition-time;

  &:hover {
    background: $gray00;
  }

  .teacher {
    align-items: center;
    display: flex;
    margin-right: 15px;
    flex: 1 1 10px;

    .avatar-wrap {
      margin-right: 15px;
      height: 40px;
      min-width: 40px;
      width: 40px;
    }

    .full-name {
      @include text('second-bold');
      @include ellipsis();
      color: $black;
      flex: 1 1 10px;
    }
  }

  .stats {
    align-items: center;
    display: flex;

    .stats-title {
      @include text('second');
      color: $gray05;
      margin-right: 10px;
    }

    .rating {
      @include text('second');
      color: $hot-sun;
      margin: 0 5px 0 5px;
    }

    .reviews {
      @include text('second');
      color: $sky-darker;
      white-space: nowrap;
    }
  }
}
