import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Event, Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { remoteConfigDefaults, RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { NotificationsService } from '@app/notifications/notifications.service';
import { loadPins, removePin } from '@app/store/actions/profile.actions';
import { CLibraryFilterData, ERedPointsEvents } from '@app/store/models';
import { getMyBillingPlan, getMyPins } from '@app/store/reducers/profile.reducer';
import {
  AccessActionEnum,
  AccountService,
  AnalyticsService,
  AuthService,
  BillingService,
  ClassroomsService,
  ConfigService,
  ContextService,
  FeaturesService,
  FilesService,
  PlatformService,
  RequestService,
  SchoolsService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import {
  AccountPlan,
  BillingSubscriptionStatusEnum,
  Classroom,
  CSchool,
  EContentPanelRoute,
  FeatureEnum,
  ImageSizeEnum,
  ITeachingSubject,
  PinnedEntity,
  Subject as BaseSubject,
  SubjectCategoryEnum,
  SubjectsRegistry,
  TenantEnum,
  User,
  UserService,
  UserStatusEnum,
} from 'lingo2-models';
import { Router as ContentRouter } from 'lingo2-models/dist/content/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { combineLatest, debounceTime, filter, map, mergeMap, Subject, takeUntil } from 'rxjs';
import { additionalMenuList, bottomMenuList, IMenuItem, topMenuList } from './left-sidebar.constants';
import { SidebarService } from './sidebar.service';

const nativeSpokenLevel = 1; // TODO вынести в константы lingo2-models

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftSidebarComponent extends ChangableComponent implements OnInit {
  @Input() floating = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClickItem = new EventEmitter<Event>();
  @ViewChild('scroll') scrollRef: ElementRef;

  public routes = EContentPanelRoute;
  public pinnedClassesList: Array<Partial<PinnedEntity>>;
  public me$ = this.contextService.me$;
  public accountRoute = AccountService.accountRoute;
  public visible = false;
  public name: string;
  public country: string;
  public isGuest: boolean;
  public isTeacher: boolean;
  public searchSubjectsTerm = '';
  public searchSubjectsList: BaseSubject[] = [];
  public subjectCategoryEnum = SubjectCategoryEnum;
  public contentFilter: CLibraryFilterData;
  public scrollActive = false;
  public displayAction = false;
  public unreadNotificationsCount$ = this.notificationsService.unreadCount$;
  public width: number;
  public classroomRoute = ClassroomsService.classroomRoute;
  public meetingFormOpened = false;
  public serviceFormOpened = false;
  public classroomModalOpened = false;
  public schoolModalOpened = false;
  public plan: AccountPlan;
  public openResumePlanModal: boolean;
  public closedPopover: boolean;
  public teaching_subjects: ITeachingSubject[];
  public meetingJoinRoute = ContentRouter.meetingJoinRoute;

  private libRouter: LibraryRouter = new LibraryRouter();
  private _timer: any;
  private _me: User;
  private _subjects: SubjectsRegistry;
  private _nativeSubjects: BaseSubject[] = [];
  private search$ = this.register(new Subject<boolean>());
  private remoteConfig: RemoteConfigType;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    private billingService: BillingService,
    private authService: AuthService,
    private contextService: ContextService,
    private configService: ConfigService,
    private features: FeaturesService,
    private screenService: ScreenService,
    private sidebarService: SidebarService,
    private notificationsService: NotificationsService,
    private classroomsService: ClassroomsService,
    private router: Router,
    private requestService: RequestService,
    private readonly store: Store,
    private remoteConfigService: RemoteConfigService,
    private analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.sidebarService.leftState$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (visible) => {
        this.visible = visible;
        this.screenService.setBodyFixed(visible);
        this.clickOutsideEnabled = visible;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.visible = false;
        this.screenService.setBodyFixed(false);
        this.clickOutsideEnabled = false;
        this.detectChanges();
      },
    });

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.width = width;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.width = window.innerWidth;
      },
    });

    combineLatest([this.configService.subjectsV2$, this.me$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([subjects, me]) => {
          this._subjects = subjects;
          this._me = me;
          this.teaching_subjects = this._me.profile.teaching_subjects;
          this.prepare();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    this.store
      .select(getMyBillingPlan)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (plan) => {
          this.plan = plan;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    this.store
      .select(getLibraryRouteData)
      .pipe(filter((v) => !!v))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.contentFilter = data;
          this.libRouter.setBaseRoute(this.contentFilter.baseRoute);
          // this.isSubjectsFilter = this.setIsLibraryRouter();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    if (this.features.isAvailable(FeatureEnum.pins)) {
      this.store
        .select(getMyPins)
        .pipe(
          filter((pinned) => !!pinned),
          map((pinned) => {
            this.pinnedClassesList = pinned;
            return pinned;
          }),
          mergeMap(() => this.screenService.height$),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: () => {
            const offsetHeight = this.scrollRef?.nativeElement.offsetHeight;
            const offsetHeightList = this.scrollRef?.nativeElement?.querySelector('.menu-with-pins')?.offsetHeight;
            this.displayAction = offsetHeight < offsetHeightList;
            this.scrollActive = true;
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          },
        });
      this.store.dispatch(loadPins());
    }

    this.search$.pipe(debounceTime(500), takeUntil(this.destroyed$)).subscribe({
      next: () => this.filterSubjectsByTerm(),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.filterSubjectsByTerm();
      },
    });

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => this.detectChanges(),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (config) => {
        this.remoteConfig = config;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.remoteConfig = remoteConfigDefaults;
        this.detectChanges();
      },
    });
  }

  public getPinCover(cover_id: string): OnUiCover {
    return { img: FilesService.getFileUrlBySize(cover_id, ImageSizeEnum.wide), aspect: '16/9' };
  }

  public get topMenuList() {
    if (this.isMobileMeVersion) {
      return topMenuList.filter((item) => item.name === 'banner-plan');
    }
    return topMenuList
      .filter((item) => {
        switch (item.name) {
          case 'menu-home':
            return this.features.isAvailable(FeatureEnum.main);

          case 'my-library':
            return !!this.isTeacher;

          case 'banner-plan':
            return (
              this.features.isAvailable(FeatureEnum.finance_plans) &&
              !!this.isTeacher &&
              !!this.remoteConfig?.appLeftSidebar__bannerPlan__show
            );

          case 'sidebar-classrooms':
            return this.features.isAvailable(FeatureEnum.classrooms);

          case 'my-feed':
            return !this.isLimitedVersion;

          case 'sidebar-favorites':
            return this.features.isAvailable(FeatureEnum.favorites_page);

          case 'sidebar-users':
            return this.features.isAvailable(FeatureEnum.following);

          case 'sidebar-notifications':
            return this.features.isAvailable(FeatureEnum.notifications) && this.deviceService.isMobile();

          case 'sidebar-quick-class':
            return (
              this.accountService.can(AccessActionEnum.canCreateDraftMeeting, this._me) &&
              !!this.remoteConfig?.appLeftSidebar__quickMeeting__show &&
              this.isLimitedVersion
            );

          case 'sidebar-schedule-class':
            return (
              this.accountService.can(AccessActionEnum.canCreateMeeting, this._me) &&
              !!this.remoteConfig?.appLeftSidebar__scheduleMeeting__show &&
              this.isLimitedVersion
            );

          case 'additional-menu':
            return !this.isLimitedVersion && this.isTeacher;

          case 'plan':
            return this.features.isAvailable(FeatureEnum.finance_plans);

          default:
            return true;
        }
      })
      .map((item) => {
        switch (item.name) {
          case 'sidebar-profile':
            item.route = `/u/${this._me?.slug}`;
            break;
        }

        return item;
      });
  }

  public get additionalMenuList() {
    return additionalMenuList;
  }

  public get isLimitedVersion(): boolean {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public get isMobileMeVersion() {
    return this.isLimitedVersion && !this.deviceService.isDesktop();
  }

  public get bottomMenuList() {
    return bottomMenuList;
  }
  public onScroll() {
    const scrollElem = this.scrollRef.nativeElement;
    if (this.scrollActive) {
      scrollElem.scrollTop = 0;
    } else {
      scrollElem.scrollTop = scrollElem.scrollHeight - scrollElem.offsetHeight;
    }
    this.scrollActive = !this.scrollActive;
    this.detectChanges();
  }

  public toggleResumePlan() {
    this.openResumePlanModal = !this.openResumePlanModal;
    this.detectChanges();
  }

  public onCheckScheduleMeeting() {
    if (this.plan?.subscription_status !== BillingSubscriptionStatusEnum.not_active) {
      this.onScheduleMeeting();
    } else {
      this.openResumePlanModal = true;
    }
  }

  public onScheduleMeeting() {
    if (this.floating) {
      this.closeSidebar();
    }

    const options = {
      caller: 'app-left-sidebar',
      reason: 'schedule meeting',
    };

    this.analytics.event(EventActionEnum.meeting_scheduling, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.scheduleMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.openMeetingForm(), options);
      } else {
        // сразу отправить на целевую страницу
        this.openMeetingForm();
      }
    };

    if (this.remoteConfig?.scheduleMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  /** Создание быстрого митинга */
  public onQuickMeeting() {
    if (this.floating) {
      this.closeSidebar();
    }

    const options = {
      caller: 'app-left-sidebar',
      reason: 'create quick meeting',
    };

    this.analytics.event(EventActionEnum.meeting_quick_creating, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.quickMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.goDraftMeeting(), options);
      } else {
        // сразу отправить на целевую страницу
        this.goDraftMeeting();
      }
    };

    if (this.remoteConfig?.quickMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  public onMenuClick() {
    if (this.floating) {
      this.closeSidebar();
    }
  }

  public onAdditionalMenuClick(item: IMenuItem) {
    this.closePopover();
    if (this.floating) {
      this.closeSidebar();
    }
    switch (item.name) {
      case 'sidebar-quick-class':
        this.onQuickMeeting();
        break;
      case 'sidebar-schedule-class':
        this.onCheckScheduleMeeting();
        break;
      case 'schedule-class-catalog':
        this.serviceFormOpened = true;
        this.detectChanges();
        break;
      case 'create-classroom':
        this.classroomModalOpened = true;
        this.detectChanges();
        break;
      case 'create-school':
        this.schoolModalOpened = true;
        this.detectChanges();
        break;
      case 'create-lesson':
        this.goCreateLesson();
        break;
      case 'create-collection':
        this.goCreateCollection();
        break;
    }
  }

  public goCreateCollection() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/content/collections']));
    if (this.isBrowser) {
      window.open(url, '_blank');
    }
  }

  public goCreateLesson() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/constructor']));
    if (this.isBrowser) {
      window.open(url, '_blank');
    }
  }

  public closePopover() {
    this.closedPopover = true;
    this.detectChanges();
  }

  public afterUserServiceCreate(e: UserService) {
    this.serviceFormOpened = false;
    this.router.navigate([LibraryRouter.userServiceRouteUniversal(e)]).catch();
  }

  public afterClassroomCreate(e: Classroom) {
    this.classroomModalOpened = false;
    this.router.navigate([this.classroomRoute(e).join('/')]).catch();
  }

  public afterSchoolCreate(e: CSchool) {
    this.schoolModalOpened = false;
    this.router.navigate([SchoolsService.schoolRoute(e, false).join('/')]).catch();
  }

  public openMeetingForm() {
    this.meetingFormOpened = true;
    this.detectChanges();
  }

  public closeMeetingForm() {
    this.meetingFormOpened = false;
    this.detectChanges();
  }

  private goDraftMeeting() {
    try {
      const url = this.requestService.host + this.router.createUrlTree(['/go/class']).toString();
      window.open(url, '_blank');
    } catch (e) {
      this.router.navigateByUrl('/go/class').catch();
    }
  }

  private filterSubjectsByTerm() {
    const filtered: BaseSubject[] = [];

    if (!!this.searchSubjectsTerm) {
      [...this._subjects.foreign, ...this._subjects.non_categorized, ...this._nativeSubjects].map((_item) => {
        if (_item.title.toLowerCase().indexOf(this.searchSubjectsTerm.toLowerCase()) >= 0) {
          filtered.push(_item);
        }
      });
    }

    this.searchSubjectsList = filtered.sort((a, b) => +b.title - +a.title);
    this.detectChanges();
  }

  public trackMenuByFn(item: IMenuItem): string {
    return item.name;
  }

  public set clickOutsideEnabled(value: boolean) {
    this.onBrowserOnly(() => {
      if (value) {
        this.clearTimeout(this._timer);
        this._timer = this.setTimeout(() => {
          this.detectChanges();
        }, 200);
      } else {
        this.detectChanges();
      }
    });
  }

  public onClick(e: Event) {
    this.onClickItem.emit(e);
  }

  public closeSidebar() {
    this.sidebarService.setLeftSidebarState(false);
  }

  public authorize() {
    this.authService.showAuthModal();
  }

  public get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  public routeLinkByItem(subject: BaseSubject) {
    this.libRouter.setSubject(subject.code);
    return this.libRouter.getLink();
  }

  public redPointEvent(name: string): ERedPointsEvents {
    switch (name) {
      case 'my-classes':
        return ERedPointsEvents.sidebar_my_classes;
      case 'my-library':
        return ERedPointsEvents.sidebar_my_library;
      case 'sidebar-favorites':
        return ERedPointsEvents.bookmark;
      default:
        return null;
    }
  }

  private prepare() {
    this.isGuest = this._me && this._me.status === UserStatusEnum.guest;
    this.isTeacher = AccountService.isAsIfTeacher(this._me);
    this.name = AccountService.getUserFullName(this._me);
    this.country = this._me && this._me.country ? this._me.country.title : null;
    if (this._me) {
      this.nativeSubjectPrepared();
    }
  }

  /**
   * Список ID языков, которыми владеет текущий пользователь
   */
  private get nativeSpokenLanguageIds(): number[] {
    return this._me.spoken_languages
      .filter((lang) => +lang.level_id === nativeSpokenLevel)
      .map((lang) => +lang.language_id);
  }

  private nativeSubjectPrepared() {
    if (!this._subjects || !this._subjects.native || !this._subjects.native.length) {
      return;
    }
    const nativeSpokenIds = this.nativeSpokenLanguageIds;
    this._nativeSubjects = this._subjects.native.filter((_s) =>
      nativeSpokenIds.find((_nId) => _nId === +_s.language_id),
    );
  }

  public trackPinByFn(item: PinnedEntity): string {
    return item.id;
  }

  public unpinClass(pin: PinnedEntity): void {
    this.store.dispatch(removePin({ pin_id: pin.id }));
  }

  public navigate(id: string) {
    this.classroomsService
      .getClassroomById(id, ['title', 'slug'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.router.navigate(this.classroomRoute(res)).catch();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public isActiveRoute(itemMenu: IMenuItem) {
    if (
      itemMenu.route === '/my-classes/dashboard' ||
      itemMenu.route === '/my-library/lessons' ||
      itemMenu.route === '/favorites/lessons' ||
      itemMenu.route === '/my-vocabulary/main'
    ) {
      const baseRoute = itemMenu.route.split('/')[1];
      return this.router.url.split('/')[1].includes(baseRoute);
    }
    return false;
  }
}
