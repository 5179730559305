import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AccountService, FilesService } from '@core/services';
import { ImageSizeEnum, User } from 'lingo2-models';
import { IAccountStats } from 'lingo2-models/dist/account/interfaces';
import { OnUiCover } from 'onclass-ui/lib/modules/on-ui-cover/on-ui-cover.component';
import { of } from 'rxjs';

type Value = Partial<User>;

@Component({
  selector: 'app-teacher-selector',
  templateUrl: './teacher-selector.component.html',
  styleUrls: ['./teacher-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TeacherSelectorComponent),
      multi: true,
    },
  ],
})
export class TeacherSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() set options(options: Array<Partial<User>>) {
    this._options = options;
  }
  public get options(): Array<Partial<User>> {
    return this._options;
  }
  @Input() error = false;
  @Output() changed = new EventEmitter<Value>();
  @Output() closed = new EventEmitter<void>();

  public selectedValue: Value;
  public isOpened: boolean;
  public isDisabled: boolean;
  public readonly: boolean;

  private _options: Array<Partial<User>> = [];

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {}

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: Partial<User>): void {
    this.selectedValue = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onChanged(option: Partial<User>) {
    this.selectedValue = option || null;
    this.propagateChange(this.selectedValue);
    this.onClosed();
    this.changed.emit(this.selectedValue);
  }

  public onClosed(): void {
    if (this.isOpened) {
      this.closed.emit();
    }
    this.isOpened = false;
  }

  public onOpened(event: Event): void {
    if (!this.isDisabled && !this.readonly) {
      this.isOpened = true;
    }
  }

  public tutorAvatar(option: Partial<User>): OnUiCover {
    return {
      type: 'avatar',
      img: FilesService.getFileUrlBySize(option?.userpic_id, ImageSizeEnum.sm),
      form: 'circle',
    };
  }

  public tutorFullName(account: Partial<User>): string {
    return AccountService.getUserFullName(account);
  }

  public tutorStats(option: Partial<User>): Partial<IAccountStats> {
    return option?.stats;
  }

  public trackByOptions(index: number, option: Partial<User>): string {
    return option.id;
  }

  public loadTutor$(id: string) {
    if (!id) {
      return of(null);
    }
    return this.accountService.getUserById(id, 'LOAD_TIME');
  }
}
