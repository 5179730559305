import { ChangeDetectorRef, forwardRef, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, UntypedFormControl } from '@angular/forms';
import { logger } from '@core/helpers/logger';
import { DropzoneConfig, FilesService, IFileType, IImageThumbnail } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { FilePurposeEnum } from 'lingo2-models';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
  selector: 'app-attachment-uploader',
  templateUrl: './attachment-uploader.component.html',
  styleUrls: ['./attachment-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentUploaderComponent),
      multi: true,
    },
  ],
})
export class AttachmentUploaderComponent extends ChangableComponent implements OnInit, ControlValueAccessor {
  @Output() changed = new EventEmitter<string>();
  public dropzoneConfig: DropzoneConfigInterface = {
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    url: '/',
  };
  public cover_file: IFileType;
  public cover_uploading = false;
  public formControl = new UntypedFormControl();
  public value = '';
  public percent = 0;

  constructor(
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.configureDropzone();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.formControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {}

  onChange(value: string): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.cdr.markForCheck();
  }

  public onUploadInit(args: any): void {}

  public onUploadProgress(args: any): void {
    logger.log('dropzone:onUploadProgress', args);
    const [, file] = args;
    this.percent = file;
  }

  public onUploadStart(args: any): void {
    this.cover_uploading = true;
  }

  public onUploadError(args: any): void {
    this.cover_uploading = false;
  }

  public onUploadSuccess(args: any): void {
    this.cover_uploading = false;
    const [, cover] = args;
    this.cover_file = cover;
    this.onChange(cover.id);
  }

  public get cover(): IImageThumbnail {
    if (!this.cover_file) {
      return null;
    }
    return this.cover_file.images.find((image) => image.size === 'md');
  }

  public onRemoved(args: any) {
    logger.log('onRemoved', args);
    this.cover_file = null;
    this.onChange(null);
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: this.dzConfig.url + '/file/upload+process?purpose=' + FilePurposeEnum.content, // особый URL для загрузки
    });
  }
}
