import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { slideDown, slideUp } from '@core/animations';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { takeUntil } from 'rxjs/operators';
import { FeaturesService, PlatformService } from '../../services';

@Component({
  selector: 'app-no-internet-connection-informer',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  animations: [
    trigger('section', [transition(':enter', useAnimation(slideDown)), transition(':leave', useAnimation(slideUp))]),
  ],
  templateUrl: './no-internet-connection-informer.component.html',
  styleUrls: ['./no-internet-connection-informer.component.scss'],
})
export class NoInternetConnectionInformerComponent extends ChangableComponent implements OnInit {
  public showWidget: boolean;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.onBrowserOnly(() => {
      this.features.isConnected$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (res) => {
          this.showWidget = !res;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.showWidget = false;
          this.detectChanges();
        },
      });
    });
  }
}
