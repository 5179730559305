import { IMenuItem } from '@models/routes';
import { EContentPanelRoute } from 'lingo2-models';

export const menuItems: IMenuItem[] = [
  {
    id: 'feed',
    label: 'menu.top.main',
    icon: 'header-home',
    route: EContentPanelRoute.main,
  },
  {
    id: 'classes',
    label: 'menu.top.classes',
    icon: 'header-classes',
    route: EContentPanelRoute.classes,
  },
  {
    id: 'teachers',
    label: 'menu.top.teachers',
    icon: 'header-teachers',
    route: EContentPanelRoute.tutors,
  },
  {
    id: 'library',
    label: 'menu.top.library',
    icon: 'footer-lessons',
    route: EContentPanelRoute.lessons,
  },
];

export const menuItemsMe: IMenuItem[] = [
  {
    id: 'my-classes',
    label: 'menu.left-sidebar.my-classes',
    icon: 'my-classes',
    route: EContentPanelRoute['my-classes/dashboard'],
  },
  {
    id: 'my-library',
    label: 'menu.left-sidebar.my-library',
    icon: 'my-library',
    route: EContentPanelRoute['my-library/lessons'],
  },
  {
    id: 'class',
    label: 'review.class',
    icon: 'plus-circle-2',
    route: null,
  },
  {
    id: 'my-vocabulary',
    label: 'menu.left-sidebar.my-vocabulary',
    icon: 'my-vocabulary',
    route: '/my-vocabulary/main' as EContentPanelRoute,
  },
  {
    id: 'calendar',
    label: 'menu.left-sidebar.schedule',
    icon: 'sidebar-schedule',
    route: '/schedule/calendar' as EContentPanelRoute,
  },
];
