import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from '@shared/icon/icon.component';
import { ButtonFabComponent } from './button-fab.component';

@NgModule({
  declarations: [ButtonFabComponent],
  imports: [CommonModule, IconComponent],
  exports: [ButtonFabComponent],
})
export class ButtonFabModule {}
