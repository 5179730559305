import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { DateFnsModule } from 'lingo2-ngx-date-fns';

import { ButtonFabModule } from 'src/app/core/components/button-fab/button-fab.module';
import { NotificationModule } from 'src/app/core/components/notification/notification.module';
import { GreetingNoticeComponent } from './greeting-notice/greeting-notice.component';
import { NotificationsWidgetComponent } from './notifications-widget/notifications-widget.component';
import { NotificationsComponent } from './notifications.component';
import { ToasterComponent } from './toaster/toaster.component';

@NgModule({
  declarations: [NotificationsComponent, ToasterComponent, NotificationsWidgetComponent, GreetingNoticeComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DateFnsModule,
    NotificationModule,
    ButtonFabModule,
    NoContentStubComponent,
    IconComponent,
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
