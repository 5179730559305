<section *ngIf="visible"
         class="user-service-form"
         [class.normal]="deviceService.isDesktop()"
         [formGroup]="form">

  <h3 class="main-title">{{ 'user-service-form-3.main-title' | translate }}</h3>

  <on-ui-form-step [stepProps]="{ label: 'user-service-form-3.steps.title1', count: stepNumber('type') }"
                   [state]="stepState('type')">

    <ng-container ngProjectAs="title">
      <ng-container *ngIf="deviceService.isDesktop()">
        <div class="col">
          <div class="row">
            <div class="col-135"></div>
            <div class="col">
              <div class="row">
                <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container ngProjectAs="content">
      <ng-container *ngIf="!deviceService.isDesktop()">
        <div class="row margin-bottom"></div>
        <div class="row margin-bottom-20">
          <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
        </div>
      </ng-container>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let typeUserService of types; let idx = index; trackBy: trackByFn">
            <on-ui-radio formControlName="type"
                         [attr.data-test]="'class-type' + typeUserService.value"
                         [error]="isInvalid('type')"
                         [radio]="typeUserService"
                         (changed)="onChangedType($event)">
            </on-ui-radio>
            <p class="type-text">{{ typeText[idx] | translate }}</p>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </on-ui-form-step>

  <on-ui-form-step [stepProps]="{ label: 'user-service-form-3.steps.title2', count: stepNumber('props') }"
                   [state]="stepState('props')">

    <ng-container ngProjectAs="content">
      <div class="step-two-wrap">
        <div class="row margin-bottom"></div>
        <div class="row margin-bottom">
          <div class="col">
            <span class="label">{{ 'user-service-form-3.fields.label1' | translate }}</span>
          </div>
          <div class="col-350">
            <ng-template [ngIf]="!deviceService.isMobile()">
              <div class="row">
                <ng-container *ngTemplateOutlet="weekdaysTemplate"></ng-container>
              </div>
            </ng-template>
          </div>
        </div>
        <ng-template [ngIf]="deviceService.isMobile()">
          <div class="row margin-bottom">
            <ng-container *ngTemplateOutlet="weekdaysTemplate"></ng-container>
          </div>
        </ng-template>

        <ng-template [ngIf]="isCountClasses()">
          <div class="row margin-bottom v-center">
            <div class="col">
              <span class="label">{{ 'user-service-form-3.fields.label2' | translate }}</span>
            </div>
            <div class="col-135">
              <on-ui-form-field-error
                [error]="isInvalid('meetings_limit') ? 'user-service-form-3.errors.error2' : null">
                <on-ui-select formControlName="meetings_limit"
                              data-test="meetings-limit"
                              [error]="isInvalid('meetings_limit')"
                              [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                              [options]="meetingsLimitOptions">
                </on-ui-select>
              </on-ui-form-field-error>
            </div>
          </div>
        </ng-template>

        <div class="row margin-bottom v-center">
          <div class="col">
            <span class="label">{{ 'user-service-form-3.fields.label3' | translate }}</span>
          </div>
          <div class="col-135">
            <on-ui-form-field-error [error]="isInvalid('duration') ? 'user-service-form-3.errors.error2' : null">
              <on-ui-select formControlName="duration"
                            data-test="duration"
                            [error]="isInvalid('duration')"
                            [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                            [options]="timeDurationOptions">
              </on-ui-select>
            </on-ui-form-field-error>
          </div>
        </div>

        <div class="row margin-bottom v-center">
          <div class="col">
            <span class="label">{{ 'user-service-form-3.fields.label4' | translate }}</span>
          </div>
          <div class="col-135">
            <on-ui-form-field-error [error]="isInvalid('price_tier') ? 'user-service-form-3.errors.error2' : null">
              <on-ui-select formControlName="price_tier"
                            data-test="price-tier"
                            [error]="isInvalid('price_tier')"
                            [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                            [options]="priceOptions" [autosize]="true">
              </on-ui-select>
            </on-ui-form-field-error>
          </div>

          <ng-template [ngIf]="!deviceService.isMobile()">
            <div class="col-135">
              <div class="period-wrap">
                <span class="label-period">{{ labelPeriod | translate }}</span>
              </div>
            </div>
          </ng-template>
        </div>

        <ng-template [ngIf]="deviceService.isMobile()">
          <div class="row margin-bottom v-center">
            <div class="col"></div>
            <div class="col-135">
              <div class="period-wrap">
                <span class="label-period">| {{ labelPeriod | translate }}</span>
              </div>
            </div>
          </div>
        </ng-template>
<!--        Temporary hide - https://app.clickup.com/t/8692ye0ek-->
<!--        <div class="row margin-bottom v-center">-->
<!--          <div class="col">-->
<!--            <span class="label">{{ 'user-service-form-3.fields.label5' | translate }}</span>-->
<!--          </div>-->
<!--          <div class="col-135">-->
<!--            <on-ui-form-field-error [error]="isInvalid('discount') ? 'user-service-form-3.errors.error2' : null">-->
<!--              <on-ui-select formControlName="discount"-->
<!--                            [error]="isInvalid('discount')"-->
<!--                            [placeholder]="'user-service-form-3.fields.placeholder1' | translate"-->
<!--                            [options]="discountOptions">-->
<!--              </on-ui-select>-->
<!--            </on-ui-form-field-error>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </ng-container>
  </on-ui-form-step>

  <on-ui-form-step [stepProps]="{ label: 'user-service-form-3.steps.title3', count: stepNumber('main') }"
                   [state]="stepState('main')">

    <ng-container ngProjectAs="content"></ng-container>
  </on-ui-form-step>

  <div class="meta row margin-bottom-15">
    <div class="image-uploader">
      <app-image-uploader formControlName="cover_id"
                          data-test="cover"
                          [display]="'wide'" [autoCrop]="true"
                          [cropRatio]="'wide'"
                          [error]="isInvalid('cover_id')">
      </app-image-uploader>
    </div>

    <div class="meta-form">
      <div class="row margin-bottom v-center">
        <div class="col">
          <span class="label">{{ 'user-service-form-3.fields.label6' | translate }}</span>
        </div>
        <div class="col-217">
          <on-ui-form-field-error [error]="isInvalid('subject_id') ? 'user-service-form-3.errors.error2' : null">
            <on-ui-select formControlName="subject_id"
                          data-test="subject"
                          [error]="isInvalid('subject_id') || !teachingSubjectsOptions?.length"
                          [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                          [options]="teachingSubjectsOptions"
                          (changed)="selectSubjectId($event)">
              <ng-template [ngIf]="!teachingSubjectsOptions?.length">
                <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
              </ng-template>
            </on-ui-select>
          </on-ui-form-field-error>
        </div>
      </div>

      <div class="row margin-bottom v-center">
        <div class="col">
          <span class="label">{{ 'user-service-form-3.fields.label7' | translate }}</span>
        </div>
        <div class="col-217">
          <on-ui-form-field-error [error]="isInvalid('category_id') ? 'user-service-form-3.errors.error2' : null">
            <on-ui-select formControlName="category_id"
                          data-test="category"
                          [error]="isInvalid('category_id')"
                          [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                          [options]="categoryOptions"
                          (changed)="onChangedCategoryId($event)">
            </on-ui-select>
          </on-ui-form-field-error>
        </div>
      </div>

      <div class="row margin-bottom v-center">
        <div class="col">
          <span class="label">{{ 'user-service-form-3.fields.label8' | translate }}</span>
        </div>
        <div class="col-217">
          <on-ui-form-field-error [error]="isInvalid('participants_limit') ? 'user-service-form-3.errors.error2' : null">
            <div class="col-80">
              <on-ui-select formControlName="participants_limit"
                            data-test="participants-limit"
                            [error]="isInvalid('participants_limit')"
                            [attr.disabled]="getControl('participants_limit').disabled"
                            [options]="participantsLimitOptions"
                            (changed)="onChangedParticipantsLimit($event)">
              </on-ui-select>
            </div>
          </on-ui-form-field-error>
        </div>
      </div>

      <div class="row margin-bottom v-center">
        <div class="col">
          <span class="label">{{ 'user-service-form-3.fields.label9' | translate }}</span>
        </div>
        <div class="col-217">
          <on-ui-form-field-error [error]="isInvalid('age_from') || isInvalid('age_to') ? 'user-service-form-3.errors.error2' : null">
            <div class="row v-center h-right">
              <div class="col-80">
                <on-ui-select formControlName="age_from"
                              data-test="age-from"
                              [error]="isInvalid('age_from')"
                              [options]="beginAgeOptions"
                              (changed)="onChangeAge($event)">
                </on-ui-select>
              </div>
              <div class="delimiter">-</div>
              <div class="col-80">
                <on-ui-select formControlName="age_to"
                              data-test="age-to"
                              [error]="isInvalid('age_to')"
                              [options]="endAgeOptions"
                              (changed)="onChangeAge($event)">
                </on-ui-select>
              </div>
            </div>
          </on-ui-form-field-error>
        </div>
      </div>
<!--      Temporary hide - https://app.clickup.com/t/8692ye0ek-->
<!--      <div class="row margin-bottom v-center">-->
<!--        <div class="col">-->
<!--          <span class="label">{{ 'user-service-form-3.fields.label10' | translate }}</span>-->
<!--        </div>-->
<!--        <div class="col-135">-->
<!--          <on-ui-select formControlName="level_id"-->
<!--                        [error]="isInvalid('level_id')"-->
<!--                        [options]="subjectLevelOptions">-->
<!--          </on-ui-select>-->
<!--        </div>-->
<!--      </div>-->

      <ng-template [ngIf]="getControl('level_id').value === -1">
        <div class="row margin-bottom v-center">
          <div class="col"></div>
          <div class="col-217">
            <on-ui-input formControlName="level_other_name"
                         data-test="level-other-name"
                         [error]="isInvalid('level_other_name')"
                         [hasCounter]="true"
                         maxlength="20">
            </on-ui-input>
          </div>
        </div>
      </ng-template>

      <div class="row margin-bottom v-center">
        <div class="col"></div>
        <div class="col-217">
          <on-ui-form-field-error [error]="isInvalid('level_id') ? 'user-service-form-3.errors.error2' : null"></on-ui-form-field-error>
        </div>
      </div>
    </div>
  </div>

  <div class="row margin-bottom">
    <div class="col">
      <on-ui-form-field-error [error]="isInvalid('title') ? 'user-service-form-3.errors.error3' : null">
        <on-ui-input formControlName="title"
                     data-test="title"
                     [error]="isInvalid('title')"
                     [hasCounter]="true"
                     maxlength="50"
                     [placeholder]="'user-service-form-3.fields.placeholder2' | translate">
        </on-ui-input>
      </on-ui-form-field-error>
    </div>
  </div>

  <div class="row margin-bottom">
    <div class="col">
      <on-ui-form-field-error [error]="isInvalid('description') ? 'user-service-form-3.errors.error4' : null">
        <on-ui-textarea formControlName="description"
                        data-test="description"
                        [error]="isInvalid('description')"
                        (changed)="onChangeDescription($event)">
        </on-ui-textarea>
      </on-ui-form-field-error>
    </div>
  </div>

  <on-ui-form-step *ngIf="isSchool" [stepProps]="{ label: 'user-service-form-3.steps.title4', count: stepNumber('teachers') }"
                   [state]="stepState('teachers')">

    <ng-container ngProjectAs="content">
      <div class="row margin-bottom"></div>
      <div formArrayName="teachers">
        <div *ngFor="let teacherItem of getFormArray('teachers')?.controls; let idx = index; trackBy: trackByFn"
             class="row margin-bottom">
          <div [class.col-560]="showAddTeacher">
            <ng-container *ngIf="showAddTeacher; else noTeachers">
            <app-teacher-selector [formControlName]="idx"
                                  [error]="isInvalid('teachers')"
                                  [options]="teachersOptions"
                                  (changed)="onChangeTeacher($event)">
            </app-teacher-selector>
            </ng-container>
            <ng-template #noTeachers>
              <div class="no-teachers-block">
              <span class="no-teachers">{{ 'user-service-form-3.teacher-selector.no-teacher' | translate }}</span>
              <on-ui-button class="primary"
                            state="default"
                            data-test="set-tutors"
                            (onclick)="setTutors()"
                            label="school-participants.action-tutor">
              </on-ui-button>
              </div>
            </ng-template>
          </div>
          <div class="col">
            <div class="teacher-actions">
              <div *ngIf="isRemoveTeacher(idx)"
                   class="teacher-action"
                   (click)="removeTeacher(idx)">
                <app-icon width="18" height="20" file="basket" icon="basket-2"></app-icon>
              </div>
              <div *ngIf="isAddTeacher(idx)"
                   class="teacher-action"
                   (click)="addTeacher()">
                <app-icon width="16" height="16" icon="plus"></app-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </on-ui-form-step>

  <div class="row margin-bottom">
    <span class="title">{{ 'user-service-form-3.titles.title5' | translate }}</span>
  </div>

  <div class="row">
    <div class="col-135">
      <on-ui-select formControlName="language_id"
                    data-test="language"
                    [error]="isInvalid('language_id')"
                    [placeholder]="'user-service-form-3.fields.placeholder1' | translate"
                    [options]="languagesOptions"
                    (changed)="onChangeLanguage($event)">
      </on-ui-select>
    </div>
    <div class="col"></div>
  </div>

  <div class="more-info" [class.active]="showMore" (click)="toggleShowMore()">
    <span>{{'user-service-form-3.show-more' | translate}}</span>
    <app-icon height="14" width="10" file="chevron" icon="down"></app-icon>
  </div>

  <ng-template [ngIf]="showMore">
  <div class="row margin-bottom-20 v-center">
    <span class="title">{{ 'user-service-form-3.titles.title1' | translate }}</span>
    <span class="label">{{ 'user-service-form-3.fields.label11' | translate }}</span>
  </div>

  <div *ngIf="!isSchool; else schoolVideo" class="video-wrap">
    <div class="row margin-bottom">
      <div class="col">
        <app-record-video formControlName="media_url"
                          data-test="media-url"
                          [file_id]="getControl('media_url').value"
                          (fileChanged)="onFileChanged($event)"
                          (recordStarted)="onRecordStarted($event)">
        </app-record-video>
      </div>
      <div class="col">
        <on-ui-bullet-list [bullet]="bullet"></on-ui-bullet-list>
      </div>
    </div>
  </div>

  <div class="row margin-bottom v-center">
    <span class="title">{{ 'user-service-form-3.titles.title2' | translate }}</span>
    <span class="label">{{ 'user-service-form-3.fields.label11' | translate }}</span>
  </div>

  <div class="row margin-bottom">
    <div class="col" formArrayName="requirements">
      <div *ngFor="let requirement of getFormArray('requirements').controls; let idx = index; trackBy: trackByFn"
           class="row margin-bottom">
        <div class="col">
          <on-ui-input [formControlName]="idx"
                       [hasCounter]="true"
                       maxlength="150"
                       [placeholder]="'user-service-form-3.fields.placeholder4' | translate"
                       (focusedChange)="onFocusedChangeRequirement($event, idx)">
          </on-ui-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row margin-bottom v-center">
    <span class="title">{{ 'user-service-form-3.titles.title3' | translate }}</span>
    <span class="label">{{ 'user-service-form-3.fields.label11' | translate }}</span>
  </div>

  <div class="row margin-bottom">
    <div class="col" formArrayName="skills">
      <div *ngFor="let skill of getFormArray('skills').controls; let idx = index; trackBy: trackByFn"
           class="row margin-bottom">
        <div class="col">
          <on-ui-input [formControlName]="idx"
                       [hasCounter]="true"
                       maxlength="150"
                       [placeholder]="'user-service-form-3.fields.placeholder5' | translate"
                       (focusedChange)="onFocusedChangeSkills($event, idx)">
          </on-ui-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row margin-bottom v-center">
    <span class="title">{{ 'user-service-form-3.titles.title4' | translate }}</span>
    <span class="label">{{ 'user-service-form-3.fields.label11' | translate }}</span>
  </div>

  <div class="row margin-bottom">
    <p class="attached-text">{{ 'user-service-form-3.attached-text' | translate }}</p>
  </div>

  <div class="row margin-bottom" formArrayName="media">
    <div class="attachment">
      <div class="attachment-scroll">
        <div class="attachment-list">
          <div *ngFor="let media of getFormArray('media').controls; let idx = index; trackBy: trackByFn"
               class="attachment-item">
            <app-attachment-uploader
              [formControlName]="idx"
              (changed)="onChangedMedia($event, idx)">
            </app-attachment-uploader>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row margin-bottom">
    <span class="upload-media-text">{{ 'user-service-form-3.upload-media-text' | translate }}</span>
  </div>

  </ng-template>

  <div class="row h-right">
    <on-ui-button class="primary"
                  state="default"
                  data-test="save"
                  [label]="(isNew ? 'user-service-form-3.actions.create' : 'user-service-form-3.actions.save') | translate"
                  (onclick)="onSave()">
    </on-ui-button>
  </div>

  <ng-template #formTemplate>
    <div class="forms-wrap" formArrayName="group">
      <div *ngFor="let groupButtonTab of getFormArray('group').controls; let idx = index; trackBy: trackByFn" class="forms-item" >
        <on-ui-button-tab [formControlName]="idx"
                          [attr.data-test]="groups[idx]?.dataTest"
                          [error]="isInvalid('group')"
                          [buttonTab]="groups[idx]"
                          (changed)="onChangedGroup($event, idx)">
        </on-ui-button-tab>
      </div>
    </div>
  </ng-template>

  <ng-template #weekdaysTemplate>
    <div class="weekdays" formArrayName="weekdays">
      <ng-template [ngIf]="isWeekdays" [ngIfElse]="weekdaysEmpty">
        <div *ngFor="let day of getFormArray('weekdays').controls; let idx = index; trackBy: trackByFn" class="day-item"
             [class.ng-invalid]="isInvalidWeekdays">
          <on-ui-button-tab [formControlName]="idx"
                            [attr.data-test]="'day' + weekdays[idx]?.value"
                            [error]="isInvalidWeekdays"
                            [buttonTab]="weekdays[idx]"
                            (changed)="onChangedWeekdays($event)">
          </on-ui-button-tab>
        </div>
      </ng-template>
      <ng-template #weekdaysEmpty>
        <span class="weekdays-empty-text">
          {{ 'user-service-form-3.weekdays-empty.text' | translate }}
          <a [routerLink]="'/schedule/working-hours'" target="_blank" class="weekdays-empty-link">
            {{ 'user-service-form-3.weekdays-empty.link' | translate }}
          </a>
        </span>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #schoolVideo>
    <div class="school-video-wrap">
      <ul class="school-video-tabs">
        <li *ngFor="let tab of teachersTabs(); trackBy: trackByFn"
            class="video-tab"
            [class.active]="isActiveVideo(tab)"
            (click)="onActiveVideo(tab)">
          {{ videoTabLabel(tab) }}
        </li>
      </ul>

      <iframe *ngIf="isMediaPrepared; else videoPlaceholder"
              [src]="safeMediaUrl"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen=""
              class="school-video">
      </iframe>

      <ng-template #videoPlaceholder>
        <div class="school-video placeholder">
          <span class="video-placeholder">{{ 'user-service-form-3.video-placeholder' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-template>
</section>

<ng-template #popoverContent>
  <div class="subject-popover">
    <span class="text">{{ 'user-service-form-3.subject-popover.text' | translate }}</span>
    <a [routerLink]="'/profile'" class="link">{{ 'user-service-form-3.subject-popover.link' | translate }}</a>
  </div>
</ng-template>
