<div class="app app-main">
  <app-header></app-header>

  <main class="app-body" [class.tablet]="deviceService.isTablet()" [class.mobile]="deviceService.isMobile()" [class.content-page]="utilsService.isContentPage">
    <app-left-sidebar [floating]="isSmallScreen"></app-left-sidebar>
    <div class="content-holder">
      <div class="content">
        <router-outlet #routerOutlet="outlet"></router-outlet>
        <app-red-points></app-red-points>
      </div>
      <app-right-sidebar *ngIf="!utilsService.isContentPage" [floating]="!isWideScreen"></app-right-sidebar>
    </div>
  </main>

  <app-footer *ngIf="isFooterVisible"></app-footer>

  <ng-container *ngIf="(deviceService.isMobile() || deviceService.isTablet()) && showMeetBtn">
    <div class="nearest-meet-btn-wrap">
      <app-nearest-meet-btn *ngIf="meeting" [meeting]="meeting"></app-nearest-meet-btn>
    </div>
  </ng-container>

  <ng-container *ngIf="isAvailableChat && isChatVisible">
    <div *ngIf="deviceService.isDesktop()" class="chat-action" [class.disabled]="!iframe">
      <app-button-fab
        [icon]="'chat-bubble'"
        [counter]="unreadCount"
        [isToggled]="isChatExpanded"
        (click)="toggleChatWindow()">
      </app-button-fab>
    </div>
    <div class="chat-wrap"
         [class.display]="isChatVisible && alternativeChatState"
         (mouseenter)="onMouseenter()"
         (mouseleave)="onMouseleave()">

         <iframe [src]="safeChatUrl" id="chatFrame"
            width="100%" sandbox="allow-same-origin allow-scripts allow-forms"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="chat">
        </iframe>
    </div>
  </ng-container>

  <ng-container *ngIf="fileFromChat">
    <app-modal *ngIf="!deviceService.isMobile()" [thin]="true" [canClose]="true" (closed)="closeFileFromChat()">
      <app-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-modal-body>
    </app-modal>

    <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeFileFromChat()">
      <app-mobile-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-container>

  <ng-container *ngIf="isAvailableNotifications">
    <app-notifications *ngIf="isNotificationsVisible" [isChatOpen]="alternativeChatState"></app-notifications>
  </ng-container>

  <ng-container *ngIf="isAvailableHttpErrors">
    <app-http-errors *ngIf="isHttpErrorsVisible && stage" [error]="httpErrorsString"></app-http-errors>
  </ng-container>

  <ng-container>
    <app-no-internet-connection-informer></app-no-internet-connection-informer>
  </ng-container>
</div>
