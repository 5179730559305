import { Injectable } from '@angular/core';
import { PlatformService } from '@core/services/platform.service';
import { DestroyableComponent } from '@models/destroyable.component';

@Injectable({
  providedIn: 'root',
})
export class ChatService extends DestroyableComponent {
  private _chatFrame: any;

  constructor(
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  public setChatFrame(frame: any) {
    this._chatFrame = frame;
  }

  public getChatFrame(): any {
    return this._chatFrame;
  }

  public openChat() {
    this.getChatFrame().contentWindow.postMessage(`chatapp::open::true`, '*');
  }

  public closeChat() {
    this.getChatFrame().contentWindow.postMessage(`chatapp::close::true`, '*');
  }

  public openPrivateChat(userId: string) {
    this.getChatFrame().contentWindow.postMessage(`chatapp::openprivate::${userId}`, '*');
  }

  public openTreadChat(thread_id: string) {
    this.getChatFrame().contentWindow.postMessage(`chatapp::openthread::${thread_id}`, '*');
  }

  public sendToChat(message: string) {
    this.getChatFrame().contentWindow.postMessage(message);
  }
}
