import {
  ChangeDetectionStrategy,
  Input,
  Output,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { ApplicationDialogsService } from '@app/shared/application-dialogs';
import { logger } from '@core/helpers/logger';
import {
  AnalyticsService,
  BillingService,
  ClassroomsService,
  FeaturesService,
  IFileType,
  MeetingsService,
  MetaService,
  SchoolsService,
} from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { addMinutes, format } from 'date-fns';
import { IMeetingFormReferences, MeetingReferencesService, PresetTypeEnum } from 'lingo2-forms';
import {
  CurrencyEnum,
  FilePurposeEnum,
  IBillingPlan,
  Meeting,
  Router as ContentRouter,
  MeetingBeginEnum,
  CSchool,
  MeetingTypeEnum,
  MeetingAccessEnum,
  BillingMeetingChargingEnum,
  FeatureEnum,
} from 'lingo2-models';
import { DateFnsConfigurationService } from 'lingo2-ngx-date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of, Subject, tap } from 'rxjs';
import { filter, mergeAll, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-meeting-form-wrapper',
  templateUrl: './meeting-form-wrapper.component.html',
  styleUrls: ['./meeting-form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingFormWrapperComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() public set meetingId(meetingId: string) {
    this.loadMeeting(meetingId);
  }
  @Input() public set day(day: Date) {
    this._day = day;
  }
  public get day(): Date {
    return this._day;
  }
  @Input() public set minute(minute: number) {
    this._minute = minute;
  }
  public get minute(): number {
    return this._minute;
  }
  @Input() preset: PresetTypeEnum = PresetTypeEnum.quick;

  @Input() public set caller(caller: string) {
    this._caller = caller;
  }

  @Output() saved = new EventEmitter<boolean>();

  public meeting: Meeting;
  public references: IMeetingFormReferences;
  public buyCreditsModalOpened = false;
  public cover: IFileType;
  public filePurpose = FilePurposeEnum.content;
  public currentPlan: IBillingPlan;
  public creditsAmount: number;
  public _school: CSchool;
  public meetingRoute = ContentRouter.meetingRoute;
  public classroomRoute = ClassroomsService.classroomRoute;
  public form = this.fb.group({
    cover_id: [],
  });

  private _day: Date;
  private _minute = 0;
  private loadCreditsSubject = this.register(new Subject<boolean>());
  private isNewMeeting = true;
  private _caller = 'app-meeting-form-wrapper';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    public dateFnsConfig: DateFnsConfigurationService,
    private dialogsService: ApplicationDialogsService,
    private meta: MetaService,
    private router: Router,
    private features: FeaturesService,
    private billingService: BillingService,
    private websocket: WebsocketService,
    private referencesService: MeetingReferencesService,
    private schoolService: SchoolsService,
    private meetingsService: MeetingsService,
    private analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.schoolService.currentSchool$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (school) => {
        this._school = school;
        if (this.meeting?.id) {
          return;
        }
        if (!school) {
          this.meeting = this.generateMeeting();
          this.isNewMeeting = true;
        } else {
          this.meeting = new Meeting({
            ...this.generateMeeting(),
            title: `${school.title} - ${format(new Date(), 'MM-dd-yyyy')}`,
            type: MeetingTypeEnum.group,
            category_id: 1,
            access: MeetingAccessEnum.public,
            charging: BillingMeetingChargingEnum.fee,
            school_id: this._school?.id,
            school: this._school,
          });
          this.isNewMeeting = true;
        }
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    of(...[this.loadCredits$, this.watchWsBalanceUpdate$, this.watchWsReconnected$])
      .pipe(mergeAll(), takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.loadReferences();
    this.loadCredits();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.meta.reset();
  }

  public loadReferences() {
    this.referencesService
      .prepareReferences()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (references) => {
          this.references = references;
          this.currentPlan = this.references.billingSettings?.plan?.plan;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }

  private generateMeeting(): Meeting {
    if (!!this.day) {
      return new Meeting({
        begin: MeetingBeginEnum.specified_time,
        begin_at: addMinutes(this.day, this.minute),
        end_at: addMinutes(this.day, this.minute + 30),
      });
    }
    return new Meeting();
  }

  /** Credits */

  private loadCredits() {
    this.loadCreditsSubject.next(true);
  }

  private get loadCredits$() {
    return this.loadCreditsSubject.pipe(
      switchMap(() => this.billingService.getAvailableAmount(CurrencyEnum.credits)),
      tap((amount: number) => {
        this.creditsAmount = amount;
        this.detectChanges();
      }),
    );
  }

  private get watchWsBalanceUpdate$() {
    return this.websocket.onBalanceUpdate.pipe(tap(() => this.loadCredits()));
  }

  private get watchWsReconnected$() {
    return this.websocket.status$.pipe(
      filter((isOnline) => isOnline),
      tap(() => this.loadCredits()),
    );
  }

  /** Billing modals */

  public buyOrUpgradePlan() {
    this.dialogsService.openPaywallModal({
      caller: this.caller || 'app-meeting-form-wrapper',
      reason: 'buy or upgrade plan',
    });
  }

  public getPlanForCredits(): void {
    this.onCloseBuyCreditsModal();
    this.dialogsService.openPaywallModal({
      caller: this.caller || 'app-meeting-form-wrapper',
      reason: 'get better credits price',
    });
  }

  public openBuyCreditsModal() {
    this.buyCreditsModalOpened = true;
    this.detectChanges();
  }

  public onCloseBuyCreditsModal() {
    this.buyCreditsModalOpened = false;
    this.detectChanges();
  }

  public onSuccessBuyCreditsModal() {
    this.buyCreditsModalOpened = false;
    this.loadCredits();
    this.detectChanges();
  }

  /** On saved */
  public onMeetingSaved(meeting: Meeting): void {
    this.saved.emit(true);
    if (meeting) {
      this.analytics.event(
        this.isNewMeeting ? EventActionEnum.meeting_scheduled : EventActionEnum.meeting_updated,
        EventCategoryEnum.service,
        this._caller,
      );
      if (meeting.classroom) {
        this.router.navigate(this.classroomRoute(meeting.classroom)).then(() => true);
      } else {
        logger.log('Classroom not found');
      }
    }
  }

  public onCoverChange(e: string): void {
    if (e) {
      this.form.controls['cover_id'].setValue(e);
      this.detectChanges();
    }
  }

  public planUpdated() {
    this.dialogsService.closePaywallModal();
  }

  private loadMeeting(id: string) {
    if (!id) {
      return;
    }

    this.isNewMeeting = false;
    this.meetingsService
      .getMeetingById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (item: Meeting) => {
          this.meeting = item;
          this.form.controls['cover_id'].setValue(this.meeting.cover_id);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get isAvailableBillingV2() {
    return this.features.isAvailable(FeatureEnum.billing_v2);
  }
}
