import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IControlOption } from '@app/shared';
import { ConfigService, ContextService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { CSchool, EContentPanelRoute, Language, SubjectsRegistry } from 'lingo2-models';
import { result } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-school-form',
  templateUrl: './school-form.component.html',
  styleUrls: ['./school-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolFormComponent extends ChangableComponent implements OnInit {
  @Input() set school(school: Partial<CSchool>) {
    this._school = school;
    this.loadModel();
  }

  public get school() {
    return this._school;
  }

  @Input() modalDisplayMode = false;
  @Input() navigateAfterCreate = false;
  @Input() fromSettings = false;
  @Output() changed = new EventEmitter<CSchool>();
  public form: UntypedFormGroup;
  public displayInvalidForm = false;
  public languagesOptions: IControlOption[] = [];
  public debug$ = this.contextService.debug$;
  public savedActionState: OnUiButtonState = 'default';

  private _subjectsRegistry: SubjectsRegistry;
  private _languages: Language[];
  private _school: Partial<CSchool>;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    public store: Store,
    private readonly contextService: ContextService,
    private readonly configService: ConfigService,
    private readonly schoolsService: SchoolsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    private router: Router,
  ) {
    super(cdr, platform);
    this.createForm();
  }

  public ngOnInit(): void {
    this.configService.subjectsV2$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (subjectsRegistry) => {
        this._subjectsRegistry = subjectsRegistry;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.configService.languages$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (languages) => {
        this._languages = languages;
        this.prepareLanguagesOptions();
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  public get subjectsRegistry(): SubjectsRegistry {
    return this._subjectsRegistry;
  }

  public isValid(formControlName: string): boolean {
    return this.form?.get(formControlName)?.valid;
  }

  public isInvalid(formControlName: string): boolean {
    return this.displayInvalidForm && this.form?.get(formControlName)?.invalid;
  }

  public isValidGroup(): boolean {
    return (
      this.isValid('cover_id') &&
      this.isValid('head_cover_id') &&
      this.isValid('title') &&
      this.isValid('short_description') &&
      this.isValid('description') &&
      this.isValid('teaching_languages')
    );
  }

  public isInvalidGroup(): boolean {
    return (
      this.isInvalid('cover_id') ||
      this.isInvalid('head_cover_id') ||
      this.isInvalid('title') ||
      this.isInvalid('short_description') ||
      this.isInvalid('description') ||
      this.isInvalid('teaching_languages')
    );
  }

  public get teachingSubjects(): UntypedFormArray {
    return this.form.get('teaching_subjects') as UntypedFormArray;
  }

  public get teachingLanguages(): UntypedFormArray {
    return this.form.get('teaching_languages') as UntypedFormArray;
  }

  public selectSubjectId(subject_id: number, idx: number) {
    this.teachingSubjects.at(idx).patchValue(subject_id);
  }

  public selectLanguageId(language_id, idx) {
    this.teachingLanguages.at(idx).patchValue(language_id);
  }

  public addSubject() {
    this.teachingSubjects.push(SchoolFormComponent.teachingSubjectForm());
  }

  public removeSubject(index: number) {
    this.teachingSubjects.removeAt(index);
    this.form.markAsDirty();
  }

  public addLanguage() {
    this.teachingLanguages.push(SchoolFormComponent.teachingLanguageForm());
  }

  public removeLanguage(index: number) {
    this.teachingLanguages.removeAt(index);
    this.form.markAsDirty();
  }

  public onChangedTitle(e) {
    this.form.markAsDirty();
  }

  public onChangedDescription(e) {
    this.form.markAsDirty();
  }

  public onSave() {
    this.displayInvalidForm = true;
    if (this.form.valid) {
      this.savedActionState = 'progress';
      const observable: Observable<CSchool> = this._school?.id
        ? this.schoolsService.updateSchool(this._school.id, this.form.value)
        : this.schoolsService.createSchool(this.form.value);
      observable.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (response) => {
          this.savedActionState = 'done';
          this.changed.emit(response);
          if (this.modalDisplayMode && !this._school?.id && !this.navigateAfterCreate) {
            this.router.navigate([EContentPanelRoute['my-classes'] + '/schools']);
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.savedActionState = 'fail';
          this.detectChanges();
        },
      });

      this.setTimeout(() => {
        this.savedActionState = 'default';
        this.detectChanges();
      }, 3000);
    }
  }

  private static teachingSubjectForm(value: number = null): AbstractControl {
    return new UntypedFormControl(value, Validators.required);
  }

  private static teachingLanguageForm(value: number = null): AbstractControl {
    return new UntypedFormControl(value, Validators.required);
  }

  private createForm() {
    this.form = new UntypedFormGroup({
      teaching_subjects: new UntypedFormArray([SchoolFormComponent.teachingSubjectForm()]),
      head_cover_id: new UntypedFormControl('', Validators.required),
      cover_id: new UntypedFormControl('', Validators.required),
      title: new UntypedFormControl('', [Validators.required, Validators.maxLength(90)]),
      short_description: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(150)]),
      teaching_languages: new UntypedFormArray([SchoolFormComponent.teachingLanguageForm()]),
    });
  }

  private prepareLanguagesOptions() {
    this.languagesOptions = this._languages.map((_l) => ({
      value: _l.id,
      title: _l.title,
    }));
  }

  private loadModel() {
    const { subjects, title, short_description, description, cover_id, head_cover_id, languages } = this._school;

    subjects?.map((_s, index) =>
      this.teachingSubjects.setControl(index, SchoolFormComponent.teachingSubjectForm(_s.id)),
    );

    languages?.map((_l, index) =>
      this.teachingLanguages.setControl(index, SchoolFormComponent.teachingLanguageForm(_l.id)),
    );

    const value = {
      title,
      short_description,
      description,
      cover_id,
      head_cover_id,
    };
    this.form.patchValue(value);
    this.form.markAsPristine();
  }

  public trackByFn(index) {
    return index;
  }
}
