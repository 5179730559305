import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { ContextService, WidgetService, WidgetsEnum, PlatformService, AccountService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IChatMessageUpdate, IPagination, Notice, NoticeInformLevelEnum, NoticeTypeEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  withLatestFrom,
  distinctUntilChanged,
  filter,
  throttleTime,
  distinctUntilKeyChanged,
  debounceTime,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { NotificationsService } from './notifications.service';
import { ChatService } from '@app/core/services/chat.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends DestroyableComponent implements OnInit {
  @Input() isChatOpen: boolean;

  public pagination: IPagination = {
    page: 1,
    pageSize: 25,
    total: null,
    totalPages: null,
  };
  public lastId = 0;
  public unreadCount$ = this.notificationsService.unreadCount$;
  public isOpened$ = this.notificationsService.isOpened$;
  public debug$ = this.contextService.debug$;

  private audio = new Audio();

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private websocket: WebsocketService,
    private notificationsService: NotificationsService,
    private accountService: AccountService,
    private router: Router,
    private widgetService: WidgetService,
    private contextService: ContextService,
    private chatService: ChatService,
    public deviceService: DeviceDetectorService,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {
    if (!this.isBrowser) {
      return;
    }

    this.audio.src = this.assetsUrl('/assets/sounds/notification.mp3');
    this.audio.volume = 0.5;
    this.audio.load();

    // Close notifications widget if other widget is opened
    this.widgetService.activeWidget$
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.isOpened$),
        filter(([type, isOpened]) => type !== (WidgetsEnum.notifications as string) && isOpened),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.close();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.close();
        },
      });

    // Set widget visibility
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd && !this.isDesktop),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (event) => {
          this.notificationsService.closeWidget();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.notificationsService.closeWidget();
        },
      });

    // Set counter and active widget
    this.isOpened$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe({
      next: (isOpened: boolean) => {
        if (isOpened) {
          this.resetUnreadCounter();
          this.widgetService.setActive(WidgetsEnum.notifications);
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    // Get unread notices count
    this.websocket.onNoticeRead.pipe(withLatestFrom(this.isOpened$), takeUntil(this.destroyed$)).subscribe({
      next: ([data]: [any, boolean]) => {
        this.notificationsService.setUnreadCount(data.unread_count);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    // Get new notice
    this.websocket.onNotice
      .pipe(
        tap((notice) => this.websocket.send('notice-received', notice)),
        withLatestFrom(this.isOpened$),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: ([notice, isOpened]) => {
          this.lastId = Math.max(this.lastId, notice.notice.id);
          this.notificationsService.pushNotice(notice.notice);
          if (!isOpened) {
            this.notificationsService.pushToast(notice.notice);
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    // Play sound on new notice
    this.websocket.onNotice
      .pipe(
        withLatestFrom(this.isOpened$),
        filter(([notice, isOpened]) => !isOpened && notice.level === NoticeInformLevelEnum.enabled),
        throttleTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: ([notice]) => {
          this.playNotificationSound();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    // Get notices page
    this.websocket.onNotices.pipe(withLatestFrom(this.isOpened$), takeUntil(this.destroyed$)).subscribe({
      next: ([pagedNotices, isOpened]) => {
        const notices = pagedNotices.results.map((res: any) => res.notice);

        const uniqueNotices = this.notificationsService.getUniqueNotices(notices);
        if (!uniqueNotices.length) {
          return;
        }

        uniqueNotices.forEach((notice: Notice) => {
          this.lastId = Math.max(this.lastId, notice.id);
        });

        this.notificationsService.pushNotices(uniqueNotices);

        this.notificationsService.setUnreadCount(pagedNotices.unread_count);

        if (!isOpened && uniqueNotices.length > 0) {
          this.playNotificationSound();

          // Show in toaster last notices
          const toasts = uniqueNotices.splice(0, pagedNotices.unread_count);
          this.notificationsService.pushToasts(toasts);
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    // Show greetings
    this.contextService.me$.pipe(distinctUntilKeyChanged('id'), takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.notificationsService.pushDayTimeGreeting(me.first_name, me.id);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    /** Ask for notices when auth complete */
    this.websocket.onAuthComplete
      .pipe(
        // filter((status) => status),
        debounceTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (user) => {
          this.getLastNotices();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        },
      });

    /** Ask for notices when connect/reconnect */
    this.websocket.status$
      .pipe(
        filter((status) => status),
        debounceTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (status) => {
          this.getLastNotices();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        },
      });

    /** Update chats on chat notice */
    this.websocket.onChatMessage.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (message) => {
        if (!this.isChatOpen && message.message) {
          // ЕСли виджет чата закрыт и месседж содержит боди
          this.pushChatToast(message); // Имитация нотификации чата, так как нет реализации на бэке
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      },
    });

    /** Ask for notices now */
    this.getLastNotices();
  }

  /** Собираем правильную структуру тоста для чата */
  private pushChatToast(message: IChatMessageUpdate) {
    // Получаем данные автора по ID так как сокет чата не возвращает объект author внутри себя
    this.accountService
      .getUserById(message.message.author_id, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.notificationsService.pushToasts([
            {
              id: 1,
              type: NoticeTypeEnum.chat_message,
              created_at: message.message.created_at,
              title: '',
              user_id: '',
              params: {
                id: new Date().getTime(),
                type: NoticeTypeEnum.chat_message,
                user_id: message.message.author_id,
                user_fullname: AccountService.getUserFullName(user),
                user_url: AccountService.accountRoute(user).join('/'),
                thread_id: message.thread_id,
                message_id: message.message_id,
                message_text: message.message.text,
              },
            },
          ]);
          this.playNotificationSound();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  public get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  public close(): void {
    this.notificationsService.closeWidget();
  }

  public toggle(): void {
    this.chatService.closeChat();
    this.notificationsService.toggleWidget();
  }

  public getMockNotices() {
    this.websocket.send('notice-mock');
    // @see this.websocket.onNotices
  }

  public getLastNotices() {
    this.websocket.send('notices', this.pagination);
    // @see this.websocket.onNotices
  }

  protected resetUnreadCounter(lastId = 0) {
    this.websocket.send('notice-read', { lastId: lastId || this.lastId });
    // @see this.websocket.onNoticeRead
  }

  public noticeType(type: NoticeTypeEnum): string {
    return NoticeTypeEnum[type] || type.toString();
  }

  private playNotificationSound() {
    this.audio.play().catch((err) => {
      console.warn('Cant play sound of notification');
    });
  }
}
