import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule, ImageUploaderModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { SubjectSelectorModule } from 'lingo2-forms';
import { OnUiAutocompleteModule, OnUiButtonModule, OnUiInputModule, OnUiTextareaModule } from 'onclass-ui';
import { SchoolFormComponent } from './school-form.component';

@NgModule({
  declarations: [SchoolFormComponent],
  exports: [SchoolFormComponent],
  imports: [
    CommonModule,
    FormFieldModule,
    ImageUploaderModule,
    OnUiAutocompleteModule,
    OnUiButtonModule,
    OnUiInputModule,
    OnUiTextareaModule,
    ReactiveFormsModule,
    SubjectSelectorModule,
    SubjectSelectorModule,
    TranslateModule,
    IconComponent,
  ],
})
export class SchoolFormModule {}
