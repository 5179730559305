import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NotificationsService } from '@app/notifications/notifications.service';
import { HeaderComponent } from '@core/header/header.component';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadFilteredLanguages, loadMyNearestMeeting } from '@store/actions/data.actions';
import { getNearestMeeting } from '@store/reducers/data.reducer';
import { getLastHttpError } from '@store/reducers/general.reducer';
import { FeatureEnum, Language, Meeting, User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ContextService,
  CrispChatService,
  FeaturesService,
  LanguageService,
  MeetingsService,
  PlatformService,
  ScreenService,
  UtilsService,
} from '../../services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccessTokenService } from '@app/core/services/access-token.service';
import { ChatService } from '@app/core/services/chat.service';

@Component({
  selector: 'app-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends ChangableComponent implements OnInit, AfterViewInit {
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }
  public isSmallScreen = this.screenService.isSmallScreen;
  public isWideScreen = this.screenService.isWideScreen;
  public isFooterVisible = true;
  public isChatVisible = false;
  public isNotificationsVisible = false;

  public isHttpErrorsVisible = false;
  public httpErrorsString: string;
  public stage = environment.env === 'staging' || environment.env === 'dev';

  public alternativeChatState = false;
  public unreadMessageCount: number;
  public meeting: Meeting;
  public showMeetBtn = false;
  public isChatExpanded = false;
  public fileFromChat = null;
  public safeChatUrl: SafeResourceUrl;
  public currentLanguage: Language;
  public iframe: any;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;
  private me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private screenService: ScreenService,
    private location: Location,
    private features: FeaturesService,
    public crispChat: CrispChatService,
    public chatService: ChatService,
    public utilsService: UtilsService,
    private readonly store: Store,
    private router: Router,
    public deviceService: DeviceDetectorService,
    public meetingsService: MeetingsService,
    public notificationsService: NotificationsService,
    private sanitizer: DomSanitizer,
    private accessTokenService: AccessTokenService,
    private languageService: LanguageService,
    protected readonly platform: PlatformService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.isSmallScreen = this.screenService.isSmallScreen;
        this.isWideScreen = this.screenService.isWideScreen;
        this.isFooterVisible = width < 870 || this.deviceService.isMobile();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.isSmallScreen = false;
        this.isWideScreen = true;
        this.isFooterVisible = false;
      },
    });

    if (this.isBrowser) {
      this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (me) => {
          this.me = me;
          this.isNotificationsVisible = me && me?.status !== UserStatusEnum.guest;
          this.isChatVisible = this.me && this.me?.status !== UserStatusEnum.guest;
          if (!this.safeChatUrl && this.safeChatUrl !== '') {
            this.generateChatLink();
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.isChatVisible = false;
          this.isNotificationsVisible = false;
        },
      });

      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (event) => {
          if (event instanceof NavigationEnd) {
            if (event.url.split('/')[1] !== 'u') {
              document.scrollingElement.scrollTop = 0;
            }
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

      this.store
        .select(getLastHttpError)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (error) => {
            if (error) {
              this.isHttpErrorsVisible = true;
              this.httpErrorsString = error.message;
              this.detectChanges();
            }
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
            this.isHttpErrorsVisible = true;
            this.httpErrorsString = error.message;
          },
        });
    }

    this.store
      .select(getNearestMeeting)
      .pipe(
        map((response) => (response || []).pop()),
        filter((meeting) => !!meeting),
        filter((meeting) => meeting.id !== this.meeting.id),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (meeting) => {
          this.meeting = new Meeting(meeting);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          if (this.me && this.me?.status !== UserStatusEnum.guest) {
            this.store.dispatch(loadMyNearestMeeting());
          }
        },
      });

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => this.detectChanges(),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    this.showMeetBtn = ['main', 'lessons', 'teachers', 'study', 'feed', 'events'].includes(
      this.location.path().split('/')[1],
    );


    this.languageService.language$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (language) => {
        this.currentLanguage = language;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadFilteredLanguages({ locale: this.me.ui_language }));
      },
    });
  }

  public ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public get isAvailableChat(): boolean {
    return this.features.isAvailable(FeatureEnum.chat) && this.isBrowser;
  }

  public get isAvailableNotifications(): boolean {
    return this.features.isAvailable(FeatureEnum.notifications) && this.isBrowser;
  }

  public get isAvailableHttpErrors(): boolean {
    return this.features.isAvailable(FeatureEnum.httpErrors);
  }

  public onChatWindowStatusChanged(status: boolean) {
    this.isChatExpanded = status;
    this.alternativeChatState = status;
    this.screenService.setBodyFixed(status);
  }

  public toggleChatWindow() {
    this.notificationsService.closeWidget();
    if (this.alternativeChatState && this.iframe) {
      this.chatService.closeChat();
    } else {
      this.chatService.openChat();
    }
    this.alternativeChatState = !this.alternativeChatState;
    this.detectChanges();
  }

  public openProfile(e) {
    this.router.navigateByUrl(`/u/${e.slug}`).then();
    if (!this.deviceService.isDesktop()) {
      this.setTimeout(() => {
        this.onChatWindowStatusChanged(false);
        this.chatService.closeChat();
      }, 200);
    }
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }

  public set unreadCount(count) {
    this.unreadMessageCount = count;
    this.detectChanges();
  }

  public get unreadCount() {
    return this.unreadMessageCount;
  }

  public openFileFromChat(event) {
    this.chatService.closeChat();
    this.fileFromChat = event;
  }

  public closeFileFromChat() {
    this.chatService.openChat();
    this.fileFromChat = null;
  }


  public generateChatLink() {
    if (!this.me?.id.length) {
      return;
    }
    let url = '';
    const hostUrl = environment.chat_app_host;
    const frameUrl = `${hostUrl}/`;
    const token = this.accessTokenService.getAccessToken();
    const host = environment.chat_app_api_host.includes('local') ? 'onclass.tech' : environment.chat_app_api_host;
    const lang = this.currentLanguage?.code || 'en';
    const myId = this.me?.id;
    url = `${frameUrl}?token=${token}&userId=${myId}&host=${host}&lang=${lang}`;
    this.safeChatUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  copyMessage(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport, so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  public receiveMessage(event: MessageEvent) {
    const message = event?.data;
    if (typeof message === 'string' || message instanceof String) {
      if (message.includes('chatapp')) {
        const messageArray = message.split('::');
        const action = messageArray[1];
        const value = messageArray[2];
        if (action && action === 'windowstatus') {
          this.onChatWindowStatusChanged(value === 'true');
        } else if (action && action === 'togglewindow') {
          // Нажали кнопку "закрыть/свернуть чат"
        } else if (action && action === 'unreadcount') {
          // Кол-во непрочитанных сообщений
          this.iframe = document.getElementById('chatFrame');
          this.chatService.setChatFrame(this.iframe);
          this.unreadCount = Number(value);
        } else if (action && action === 'openprofile') {
          // Перейти в профиль
          if (!!value.length) {
            this.openProfile(value);
          }
        } else if (action && action === 'openfile') {
          // Открыть файл из чата
          if (!!value.length) {
            this.openFileFromChat(value);
          }
        } else if (action && action === 'opensupport') {
          // Чат суппорта
          this.crispChat.openChat();
        } else if (action && action === 'copymessage') {
          // копирование сообщения
          this.copyMessage(value);
        }
      }
    }
  }
}
