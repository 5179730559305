import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PaymentWizardDialogComponent } from '@app/account/finance-page/components/payment-wizard-dialog/payment-wizard-dialog.component';
import { ApplicationDialogsService } from '@app/shared/application-dialogs';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { SidebarService } from '@core/layouts/main/sidebar/sidebar.service';
import { BillingService, FeaturesService, PlatformService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { loadMyBillingPlan } from '@store/actions/profile.actions';
import { getMe, getMyBillingPlan } from '@store/reducers/profile.reducer';
import {
  FinancePlanActivateFormModule,
  FinancePlanActivateFormV2Module,
  FinancePlanBuyCreditsFormModule,
  FinancePlanBuyCreditsFormV2Module,
} from 'lingo2-forms';
import { CurrencyEnum, FeatureEnum, AccountPlan, BillingSubscriptionStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiPopoverModule } from 'onclass-ui';
import { of, tap } from 'rxjs';
import { filter, mergeAll, switchMap, takeUntil, map, distinctUntilChanged } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";
import {IconComponent} from "@shared/icon/icon.component";

@Component({
  selector: 'app-banner-plan',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    FinancePlanBuyCreditsFormModule,
    MobileModalModule,
    FinancePlanActivateFormModule,
    OnUiPopoverModule,
    FinancePlanBuyCreditsFormV2Module,
    FinancePlanActivateFormV2Module,
    OnUiButtonModule,
    PaymentWizardDialogComponent,
    IconComponent,
  ],
  templateUrl: './banner-plan.component.html',
  styleUrls: ['./banner-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerPlanComponent extends ChangableComponent implements OnInit {
  @Input() mini: boolean;
  public creditsAmount = 0;
  public expiredDate: Date;
  public isNotActiveSubscription: boolean;
  public buyCreditsModalOpened: boolean;
  public openResumePlanModal: boolean;

  public activePlan: AccountPlan;
  public isPopoverClose = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private billingService: BillingService,
    private sidebarService: SidebarService,
    private features: FeaturesService,
    private dialogsService: ApplicationDialogsService,
    private readonly store: Store,
    private websocket: WebsocketService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.watchMe$
      .pipe(
        tap(() => {
          this.loadMyBillingPlan();
          this.loadCredits();
        }),
        switchMap(() =>
          of(
            ...[
              this.watchMyBillingPlan$,
              this.watchWsPlanUpdate$,
              this.watchWsBalanceUpdate$,
              this.watchWsReconnected$,
            ],
          ).pipe(mergeAll()),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  public buyOrUpgradePlan() {
    this.dialogsService.openPaywallModal({
      caller: 'app-banner-plan',
      reason: 'buy or upgrade plan',
    });
    this.setTimeout(() => {
      this.isPopoverClose = false;
      this.detectChanges();
    }, 100);
    this.isPopoverClose = true;
    this.detectChanges();
  }

  public planUpdated() {
    this.dialogsService.closePaywallModal();
    this.sidebarService.toggleLeftSidebar();
    this.detectChanges();
  }

  public getPlanForCredits() {
    this.closeBuyCreditsModal();
    this.dialogsService.openPaywallModal({
      caller: 'app-banner-plan',
      reason: 'buy or upgrade plan',
    });
    this.sidebarService.toggleLeftSidebar();
    this.detectChanges();
  }

  public openBuyCreditsModal() {
    this.buyCreditsModalOpened = true;
    this.isPopoverClose = true;
    this.sidebarService.toggleLeftSidebar();
    this.detectChanges();
  }

  public closeBuyCreditsModal() {
    this.buyCreditsModalOpened = false;
    this.isPopoverClose = false;
    this.loadCredits();
    this.detectChanges();
  }

  public get closePopover() {
    return this.isPopoverClose;
  }

  private get watchMe$() {
    return this.store.select(getMe).pipe(
      filter((me) => !!me),
      map((me) => me?.id),
      distinctUntilChanged(),
    );
  }

  private get watchMyBillingPlan$() {
    return this.store.select(getMyBillingPlan).pipe(
      tap((activePlan) => {
        this.activePlan = activePlan;
        this.expiredDate = activePlan?.expires_at;
        this.isNotActiveSubscription = activePlan?.subscription_status === BillingSubscriptionStatusEnum.not_active;
        this.detectChanges();
      }),
    );
  }

  private get watchWsPlanUpdate$() {
    return this.websocket.onPlanUpdate.pipe(tap(() => this.loadMyBillingPlan()));
  }

  private loadMyBillingPlan() {
    this.store.dispatch(loadMyBillingPlan());
  }

  public toggleResumePlan() {
    this.openResumePlanModal = !this.openResumePlanModal;
    this.isPopoverClose = this.openResumePlanModal;
    this.detectChanges();
  }

  private loadCredits() {
    this.billingService
      .getAvailableAmount(CurrencyEnum.credits)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (amount: number) => {
          this.creditsAmount = amount;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  private get watchWsBalanceUpdate$() {
    return this.websocket.onBalanceUpdate.pipe(tap(() => this.loadCredits()));
  }

  private get watchWsReconnected$() {
    return this.websocket.status$.pipe(
      filter((isOnline) => isOnline),
      tap(() => {
        this.loadCredits();
        this.loadMyBillingPlan();
      }),
    );
  }

  public get isAvailableBillingV2() {
    return this.features.isAvailable(FeatureEnum.billing_v2);
  }
}
