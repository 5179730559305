<div class="teacher-selector"
     [class.opened]="isOpened"
     [class.error]="error"
     (clickOutside)="onClosed()">
  <div class="teacher-selector-select" (click)="onOpened($event)">
    <app-icon width="12" height="7" class="teacher-selector-chevron" file="chevron" icon="up"></app-icon>
    <ng-template [ngIf]="!selectedValue" [ngIfElse]="valueSelected">
      <span class="placeholder">{{ 'user-service-form-3.teacher-selector.placeholder' | translate }}</span>
    </ng-template>
    <ng-template #valueSelected>
      <ng-container *ngTemplateOutlet="teacherOption; context:{ option: selectedValue }"></ng-container>
    </ng-template>
  </div>

  <div *ngIf="isOpened" class="teacher-selector-dropdown">
    <ng-template ngFor let-option [ngForOf]="options" let-idx="index" [ngForTrackBy]="trackByOptions">
      <ng-container *ngTemplateOutlet="teacherOption; context:{ option }"></ng-container>
    </ng-template>
  </div>
</div>

<ng-template #teacherOption let-option="option">
  <div class="teacher-option"
       (click)="onChanged(option)">
    <div class="teacher">
      <div class="avatar-wrap">
        <on-ui-cover [cover]="tutorAvatar(loadTutor$(option.id) | async)"></on-ui-cover>
      </div>
      <span class="full-name">{{ tutorFullName(option) }}</span>
    </div>
    <app-rating [stats]="tutorStats(option)" [label]="true"></app-rating>
  </div>
</ng-template>
