import { EUserServiceType, WeekdaysEnum } from 'lingo2-models';
import { OnUiBullet } from 'onclass-ui/lib/modules/on-ui-bullet-list/on-ui-bullet-list.component';
import { OnUiButtonTabOption } from 'onclass-ui/lib/modules/on-ui-button-tab/on-ui-button-tab.component';
import { OnUiRadioOption } from 'onclass-ui/lib/modules/on-ui-radio/on-ui-radio.component';
import { OnUiSelectOption } from 'onclass-ui/lib/modules/on-ui-select/on-ui-select.component';

export const userServicesTypes: OnUiRadioOption[] = [
  {
    value: EUserServiceType.single,
    label: 'user-service-form-3.types.label1',
    text: 'user-service-form-3.types.text1',
  },
  {
    value: EUserServiceType.regular,
    label: 'user-service-form-3.types.label2',
    text: 'user-service-form-3.types.text2',
  },
  {
    value: EUserServiceType.mini,
    label: 'user-service-form-3.types.label3',
    text: 'user-service-form-3.types.text3',
  },
  {
    value: EUserServiceType.course,
    label: 'user-service-form-3.types.label4',
    text: 'user-service-form-3.types.text4',
  },
];

export const bullet: OnUiBullet = {
  title: 'user-service-form-3.bullet.title',
  list: [
    'user-service-form-3.bullet.item1',
    'user-service-form-3.bullet.item2',
    'user-service-form-3.bullet.item3',
    'user-service-form-3.bullet.item4',
    'user-service-form-3.bullet.item5',
  ],
};

export const typeText: string[] = [
  'user-service-form-3.types.type-text1',
  'user-service-form-3.types.type-text2',
  'user-service-form-3.types.type-text3',
  'user-service-form-3.types.type-text4',
];

export const participantsSingleLimit: OnUiSelectOption[] = [{ value: 1, title: '1' }];

export const participantsGroupLimit: OnUiSelectOption[] = [
  { value: 2, title: '2' },
  { value: 3, title: '3' },
  { value: 4, title: '4' },
  { value: 5, title: '5' },
  { value: 6, title: '6' },
  { value: 7, title: '7' },
  { value: 8, title: '8' },
  { value: 9, title: '9' },
  { value: 10, title: '10' },
  { value: 11, title: '11' },
  { value: 12, title: '12' },
  { value: 13, title: '13' },
  { value: 14, title: '14' },
  { value: 15, title: '15' },
  { value: 16, title: '16' },
  { value: 17, title: '17' },
  { value: 18, title: '18' },
  { value: 19, title: '19' },
  { value: 20, title: '20' },
];

export const discountOptions: OnUiSelectOption[] = [
  { value: -1, title: 'user-service-form-3.fields.placeholder6' },
  { value: 20, title: '20%' },
  { value: 30, title: '30%' },
  { value: 40, title: '40%' },
  { value: 50, title: '50%' },
];

export const timeDurationOptions: number[] = [30, 40, 50, 60, 80, 90, 120, 150, 180, 240, 300];

export const meetingsLimitOptions: OnUiSelectOption[] = [
  { value: 1, title: '1' },
  { value: 2, title: '2' },
  { value: 3, title: '3' },
  { value: 4, title: '4' },
  { value: 5, title: '5' },
  { value: 6, title: '6' },
  { value: 7, title: '7' },
  { value: 8, title: '8' },
  { value: 9, title: '9' },
  { value: 10, title: '10' },
  { value: 11, title: '11' },
  { value: 12, title: '12' },
];

export const meetingsLimitMiniCourseOptions: OnUiSelectOption[] = [
  { value: 2, title: '2' },
  { value: 3, title: '3' },
  { value: 4, title: '4' },
  { value: 5, title: '5' },
];

export const meetingsLimitCourseOptions: OnUiSelectOption[] = [
  { value: 6, title: '6' },
  { value: 7, title: '7' },
  { value: 8, title: '8' },
  { value: 9, title: '9' },
  { value: 10, title: '10' },
  { value: 11, title: '11' },
  { value: 12, title: '12' },
];

export const weekdaysOptions: OnUiButtonTabOption[] = [
  { value: WeekdaysEnum.sunday, label: 'days-of-week.su' },
  { value: WeekdaysEnum.monday, label: 'days-of-week.mo' },
  { value: WeekdaysEnum.tuesday, label: 'days-of-week.tu' },
  { value: WeekdaysEnum.wednesday, label: 'days-of-week.we' },
  { value: WeekdaysEnum.thursday, label: 'days-of-week.th' },
  { value: WeekdaysEnum.friday, label: 'days-of-week.fr' },
  { value: WeekdaysEnum.saturday, label: 'days-of-week.sa' },
];

export const groupsOptions: Array<OnUiButtonTabOption & { dataTest: string }> = [
  { value: 1, label: 'user-service-form-3.single-label', dataTest: 'single' },
  { value: 2, label: 'user-service-form-3.group-label', dataTest: 'group' },
];
