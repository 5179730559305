import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RatingComponent } from '@shared/rating/rating.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { OnUiCoverModule } from 'onclass-ui';
import { TeacherSelectorComponent } from './teacher-selector.component';
import {IconComponent} from "@shared/icon/icon.component";

@NgModule({
  declarations: [TeacherSelectorComponent],
  exports: [TeacherSelectorComponent],
    imports: [CommonModule, ClickOutsideModule, OnUiCoverModule, TranslateModule, RatingComponent, IconComponent],
})
export class TeacherSelectorModule {}
