import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RouterOutlet, Router } from '@angular/router';
import { FeaturesService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightSidebarComponent extends ChangableComponent implements OnInit {
  @Input() floating: boolean;
  public visible = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private sidebarService: SidebarService,
    private screenService: ScreenService,
    private router: Router,
    private features: FeaturesService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.sidebarService.rightState$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (visible) => {
        this.visible = visible && !this.isSystemPages;
        this.screenService.setBodyFixed(this.visible);
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.visible = false;
        this.screenService.setBodyFixed(false);
        this.detectChanges();
      },
    });

    this.features.tenant$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => this.detectChanges(),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });
  }

  public get isSystemPages() {
    const urlList = [
      '/finance',
      '/settings',
      '/my-classes/',
      '/my-library/',
      '/my-vocabulary',
      '/events',
      '/schedule',
      '/users',
      '/favorites',
      '/notifications',
      '/classes/join',
    ];
    return !!urlList.find((url) => this.router.url.includes(url));
  }

  public isOutletActivated(outlet: RouterOutlet): boolean {
    return outlet.isActivated;
  }

  public onToggleSidebar() {
    this.sidebarService.toggleRightSidebar();
  }

  get isMainPage(): boolean {
    const mainUrls = ['/feed', '/dashboard', '/me/teacher'];
    return !!mainUrls.find((url) => this.router.url.includes(url));
  }
}
